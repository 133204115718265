import React, { useState, useEffect } from 'react';
import '../flight-booking-main/flight-booking-main.css'
import FlightBookingMainLeft from '../flight-booking-main/flight-booking-main-left'
import FlightBookingMainRight from '../flight-booking-main/flight-booking-main-right'
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

const FlightBookingMain = ({
  data,
  traveltype,
  tripinfo,
  onUpdate,
  airlines,
}) => {
  const location = useLocation();
  const FlightData = data[0].flight;
  const Search_Key = data[0].Search_Key;
  const fareid = data[0].fareid;
  const charges = data[0].charges;
  const adult = data[0].adultcount;
  const faredetails = data[0].item;

  let sum = 0;
  data.forEach((element) => {
    sum =
      sum +
      (Number(
        element.item.FareDetails.find((fare) => fare.PAX_Type === 0)
          ?.Total_Amount || 0
      ) *
        Number(adult.adult || 0) +
        Number(charges) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 1)
            ?.Total_Amount || 0
        ) *
          Number(adult.child || 0) +
        Number(
          element.item.FareDetails.find((fare) => fare.PAX_Type === 2)
            ?.Total_Amount || 0
        ) *
          Number(adult.infant || 0));
  });

  const [selectedssrOptions, setSelectedssrOptions] = useState([]);
  const ssrsum = selectedssrOptions.reduce((total, item) => {
    return total + item.Total_Amount; // Add the amount if it exists, otherwise add 0
  }, 0);

  const bookingamount = Number(sum) + Number(ssrsum);

  return (
    <section className="flight-booking">
      <div className="contain">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="row">
          <FlightBookingMainLeft
            data={data}
            traveltype={traveltype}
            tripinfo={tripinfo}
            bookingamount={bookingamount}
            onupdatessr={(updatedItem) => {
              setSelectedssrOptions(updatedItem.list);
            }}
          />

          <FlightBookingMainRight
            data={data}
            ssrselect={selectedssrOptions}
            bookingamount={bookingamount}
            onUpdate={(updatedItem) => {
              onUpdate({});
            }}
            airlines={airlines}
          />
        </div>
      </div>
    </section>
  );
};
// aa

export default FlightBookingMain;