import React, { useEffect, useState } from 'react';

import { IMAGE_BASE_URL, airline_code } from '../API/endpoints'
import { post } from '../API/apiHelper'
import progress from '../assets/loading.gif'

import logo from '../assets/images/logo.png';

const AirlineLogo = ({ airCode, type, airlinelist }) => {

    const [airlines, setairlines] = useState([])
    async function get_airlines() {
        try {
            const response = await post(airline_code, {}, true);
            const res = await response.json();
            setairlines(res.data.rows)
        } catch (error) {
        }
    }

    useEffect(() => {
        if (type === 1) {
            get_airlines()
        } else {
            setairlines(airlinelist)
        }

    }, [airCode]);


    return (
        <div>
            {
                (airlines) ? (
                    <img src={IMAGE_BASE_URL + airlines.find((data) => data.code == airCode)?.logo} alt={`${airCode} logo`} style={{ height: '50px', width: '50px' }} />

                ) : (
                    <progress />
                )
            }
        </div>
    );
};



export default AirlineLogo;
