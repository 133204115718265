  const countries = [
    { code: "AF", alpha3Code: "AFG", currency: "AFN", name: "Afghanistan" },
    { code: "AL", alpha3Code: "ALB", currency: "ALL", name: "Albania" },
    { code: "DZ", alpha3Code: "DZA", currency: "DZD", name: "Algeria" },
    { code: "AS", alpha3Code: "ASM", currency: "USD", name: "American Samoa" },
    { code: "AD", alpha3Code: "AND", currency: "EUR", name: "Andorra" },
    { code: "AO", alpha3Code: "AGO", currency: "AOA", name: "Angola" },
    {
      code: "AG",
      alpha3Code: "ATG",
      currency: "XCD",
      name: "Antigua and Barbuda",
    },
    { code: "AR", alpha3Code: "ARG", currency: "ARS", name: "Argentina" },
    { code: "AM", alpha3Code: "ARM", currency: "AMD", name: "Armenia" },
    { code: "AU", alpha3Code: "AUS", currency: "AUD", name: "Australia" },
    { code: "AT", alpha3Code: "AUT", currency: "EUR", name: "Austria" },
    { code: "AZ", alpha3Code: "AZE", currency: "AZN", name: "Azerbaijan" },
    { code: "BS", alpha3Code: "BHS", currency: "BSD", name: "Bahamas" },
    { code: "BH", alpha3Code: "BHR", currency: "BHD", name: "Bahrain" },
    { code: "BD", alpha3Code: "BGD", currency: "BDT", name: "Bangladesh" },
    { code: "BB", alpha3Code: "BRB", currency: "BBD", name: "Barbados" },
    { code: "BY", alpha3Code: "BLR", currency: "BYN", name: "Belarus" },
    { code: "BE", alpha3Code: "BEL", currency: "EUR", name: "Belgium" },
    { code: "BZ", alpha3Code: "BLZ", currency: "BZD", name: "Belize" },
    { code: "BJ", alpha3Code: "BEN", currency: "XOF", name: "Benin" },
    { code: "BT", alpha3Code: "BTN", currency: "BTN", name: "Bhutan" },
    { code: "BO", alpha3Code: "BOL", currency: "BOB", name: "Bolivia" },
    {
      code: "BA",
      alpha3Code: "BIH",
      currency: "BAM",
      name: "Bosnia and Herzegovina",
    },
    { code: "BW", alpha3Code: "BWA", currency: "BWP", name: "Botswana" },
    { code: "BR", alpha3Code: "BRA", currency: "BRL", name: "Brazil" },
    {
      code: "BN",
      alpha3Code: "BRN",
      currency: "BND",
      name: "Brunei Darussalam",
    },
    { code: "BG", alpha3Code: "BGR", currency: "BGN", name: "Bulgaria" },
    { code: "BF", alpha3Code: "BFA", currency: "XOF", name: "Burkina Faso" },
    { code: "BI", alpha3Code: "BDI", currency: "BIF", name: "Burundi" },
    { code: "KH", alpha3Code: "KHM", currency: "KHR", name: "Cambodia" },
    { code: "CM", alpha3Code: "CMR", currency: "XAF", name: "Cameroon" },
    { code: "CA", alpha3Code: "CAN", currency: "CAD", name: "Canada" },
    { code: "CV", alpha3Code: "CPV", currency: "CVE", name: "Cape Verde" },
    {
      code: "CF",
      alpha3Code: "CAF",
      currency: "XAF",
      name: "Central African Republic",
    },
    { code: "TD", alpha3Code: "TCD", currency: "XAF", name: "Chad" },
    { code: "CL", alpha3Code: "CHL", currency: "CLP", name: "Chile" },
    { code: "CN", alpha3Code: "CHN", currency: "CNY", name: "China" },
    { code: "CO", alpha3Code: "COL", currency: "COP", name: "Colombia" },
    { code: "KM", alpha3Code: "COM", currency: "KMF", name: "Comoros" },
    { code: "CG", alpha3Code: "COG", currency: "XAF", name: "Congo" },
    { code: "CR", alpha3Code: "CRI", currency: "CRC", name: "Costa Rica" },
    { code: "HR", alpha3Code: "HRV", currency: "HRK", name: "Croatia" },
    { code: "CU", alpha3Code: "CUB", currency: "CUP", name: "Cuba" },
    { code: "CY", alpha3Code: "CYP", currency: "EUR", name: "Cyprus" },
    { code: "CZ", alpha3Code: "CZE", currency: "CZK", name: "Czech Republic" },
    { code: "DK", alpha3Code: "DNK", currency: "DKK", name: "Denmark" },
    { code: "DJ", alpha3Code: "DJI", currency: "DJF", name: "Djibouti" },
    { code: "DM", alpha3Code: "DMA", currency: "XCD", name: "Dominica" },
    {
      code: "DO",
      alpha3Code: "DOM",
      currency: "DOP",
      name: "Dominican Republic",
    },
    { code: "EC", alpha3Code: "ECU", currency: "USD", name: "Ecuador" },
    { code: "EG", alpha3Code: "EGY", currency: "EGP", name: "Egypt" },
    { code: "SV", alpha3Code: "SLV", currency: "USD", name: "El Salvador" },
    {
      code: "GQ",
      alpha3Code: "GNQ",
      currency: "XAF",
      name: "Equatorial Guinea",
    },
    { code: "ER", alpha3Code: "ERI", currency: "ERN", name: "Eritrea" },
    { code: "EE", alpha3Code: "EST", currency: "EUR", name: "Estonia" },
    { code: "ET", alpha3Code: "ETH", currency: "ETB", name: "Ethiopia" },
    { code: "FJ", alpha3Code: "FJI", currency: "FJD", name: "Fiji" },
    { code: "FI", alpha3Code: "FIN", currency: "EUR", name: "Finland" },
    { code: "FR", alpha3Code: "FRA", currency: "EUR", name: "France" },
    { code: "GA", alpha3Code: "GAB", currency: "XAF", name: "Gabon" },
    { code: "GM", alpha3Code: "GMB", currency: "GMD", name: "Gambia" },
    { code: "GE", alpha3Code: "GEO", currency: "GEL", name: "Georgia" },
    { code: "DE", alpha3Code: "DEU", currency: "EUR", name: "Germany" },
    { code: "GH", alpha3Code: "GHA", currency: "GHS", name: "Ghana" },
    { code: "GR", alpha3Code: "GRC", currency: "EUR", name: "Greece" },
    { code: "GD", alpha3Code: "GRD", currency: "XCD", name: "Grenada" },
    { code: "GT", alpha3Code: "GTM", currency: "GTQ", name: "Guatemala" },
    { code: "GN", alpha3Code: "GIN", currency: "GNF", name: "Guinea" },
    { code: "GW", alpha3Code: "GNB", currency: "XOF", name: "Guinea-Bissau" },
    { code: "GY", alpha3Code: "GUY", currency: "GYD", name: "Guyana" },
    { code: "HT", alpha3Code: "HTI", currency: "HTG", name: "Haiti" },
    { code: "HN", alpha3Code: "HND", currency: "HNL", name: "Honduras" },
    { code: "HU", alpha3Code: "HUN", currency: "HUF", name: "Hungary" },
    { code: "IS", alpha3Code: "ISL", currency: "ISK", name: "Iceland" },
    { code: "IN", alpha3Code: "IND", currency: "INR", name: "India" },
    { code: "ID", alpha3Code: "IDN", currency: "IDR", name: "Indonesia" },
    { code: "IR", alpha3Code: "IRN", currency: "IRR", name: "Iran" },
    { code: "IQ", alpha3Code: "IRQ", currency: "IQD", name: "Iraq" },
    { code: "IE", alpha3Code: "IRL", currency: "EUR", name: "Ireland" },
    { code: "IL", alpha3Code: "ISR", currency: "ILS", name: "Israel" },
    { code: "IT", alpha3Code: "ITA", currency: "EUR", name: "Italy" },
    { code: "JM", alpha3Code: "JAM", currency: "JMD", name: "Jamaica" },
    { code: "JP", alpha3Code: "JPN", currency: "JPY", name: "Japan" },
    { code: "JO", alpha3Code: "JOR", currency: "JOD", name: "Jordan" },
    { code: "KZ", alpha3Code: "KAZ", currency: "KZT", name: "Kazakhstan" },
    { code: "KE", alpha3Code: "KEN", currency: "KES", name: "Kenya" },
    { code: "KI", alpha3Code: "KIR", currency: "AUD", name: "Kiribati" },
    { code: "KP", alpha3Code: "PRK", currency: "KPW", name: "North Korea" },
    { code: "KR", alpha3Code: "KOR", currency: "KRW", name: "South Korea" },
    { code: "KW", alpha3Code: "KWT", currency: "KWD", name: "Kuwait" },
    { code: "KG", alpha3Code: "KGZ", currency: "KGS", name: "Kyrgyzstan" },
    { code: "LA", alpha3Code: "LAO", currency: "LAK", name: "Laos" },
    { code: "LV", alpha3Code: "LVA", currency: "EUR", name: "Latvia" },
    { code: "LB", alpha3Code: "LBN", currency: "LBP", name: "Lebanon" },
    { code: "LS", alpha3Code: "LSO", currency: "LSL", name: "Lesotho" },
    { code: "LR", alpha3Code: "LBR", currency: "LRD", name: "Liberia" },
    { code: "LY", alpha3Code: "LBY", currency: "LYD", name: "Libya" },
    { code: "LI", alpha3Code: "LIE", currency: "CHF", name: "Liechtenstein" },
    { code: "LT", alpha3Code: "LTU", currency: "EUR", name: "Lithuania" },
    { code: "LU", alpha3Code: "LUX", currency: "EUR", name: "Luxembourg" },
    { code: "MG", alpha3Code: "MDG", currency: "MGA", name: "Madagascar" },
    { code: "MW", alpha3Code: "MWI", currency: "MWK", name: "Malawi" },
    { code: "MY", alpha3Code: "MYS", currency: "MYR", name: "Malaysia" },
    { code: "MV", alpha3Code: "MDV", currency: "MVR", name: "Maldives" },
    { code: "ML", alpha3Code: "MLI", currency: "XOF", name: "Mali" },
    { code: "MT", alpha3Code: "MLT", currency: "EUR", name: "Malta" },
    {
      code: "MH",
      alpha3Code: "MHL",
      currency: "USD",
      name: "Marshall Islands",
    },
    { code: "MR", alpha3Code: "MRT", currency: "MRU", name: "Mauritania" },
    { code: "MU", alpha3Code: "MUS", currency: "MUR", name: "Mauritius" },
    { code: "MX", alpha3Code: "MEX", currency: "MXN", name: "Mexico" },
    { code: "FM", alpha3Code: "FSM", currency: "USD", name: "Micronesia" },
    { code: "MD", alpha3Code: "MDA", currency: "MDL", name: "Moldova" },
    { code: "MC", alpha3Code: "MCO", currency: "EUR", name: "Monaco" },
    { code: "MN", alpha3Code: "MNG", currency: "MNT", name: "Mongolia" },
    { code: "ME", alpha3Code: "MNE", currency: "EUR", name: "Montenegro" },
    { code: "MA", alpha3Code: "MAR", currency: "MAD", name: "Morocco" },
    { code: "MZ", alpha3Code: "MOZ", currency: "MZN", name: "Mozambique" },
    { code: "MM", alpha3Code: "MMR", currency: "MMK", name: "Myanmar" },
    { code: "NA", alpha3Code: "NAM", currency: "NAD", name: "Namibia" },
    { code: "NR", alpha3Code: "NRU", currency: "AUD", name: "Nauru" },
    { code: "NP", alpha3Code: "NPL", currency: "NPR", name: "Nepal" },
    { code: "NL", alpha3Code: "NLD", currency: "EUR", name: "Netherlands" },
    { code: "NZ", alpha3Code: "NZL", currency: "NZD", name: "New Zealand" },
    { code: "NI", alpha3Code: "NIC", currency: "NIO", name: "Nicaragua" },
    { code: "NE", alpha3Code: "NER", currency: "XOF", name: "Niger" },
    { code: "NG", alpha3Code: "NGA", currency: "NGN", name: "Nigeria" },
    { code: "NO", alpha3Code: "NOR", currency: "NOK", name: "Norway" },
    { code: "OM", alpha3Code: "OMN", currency: "OMR", name: "Oman" },
    { code: "PK", alpha3Code: "PAK", currency: "PKR", name: "Pakistan" },
    { code: "PW", alpha3Code: "PLW", currency: "USD", name: "Palau" },
    { code: "PA", alpha3Code: "PAN", currency: "PAB", name: "Panama" },
    {
      code: "PG",
      alpha3Code: "PNG",
      currency: "PGK",
      name: "Papua New Guinea",
    },
    { code: "PY", alpha3Code: "PRY", currency: "PYG", name: "Paraguay" },
    { code: "PE", alpha3Code: "PER", currency: "PEN", name: "Peru" },
    { code: "PH", alpha3Code: "PHL", currency: "PHP", name: "Philippines" },
    { code: "PL", alpha3Code: "POL", currency: "PLN", name: "Poland" },
    { code: "PT", alpha3Code: "PRT", currency: "EUR", name: "Portugal" },
    { code: "QA", alpha3Code: "QAT", currency: "QAR", name: "Qatar" },
    { code: "RO", alpha3Code: "ROU", currency: "RON", name: "Romania" },
    { code: "RU", alpha3Code: "RUS", currency: "RUB", name: "Russia" },
    { code: "RW", alpha3Code: "RWA", currency: "RWF", name: "Rwanda" },
    {
      code: "KN",
      alpha3Code: "KNA",
      currency: "XCD",
      name: "Saint Kitts and Nevis",
    },
    { code: "LC", alpha3Code: "LCA", currency: "XCD", name: "Saint Lucia" },
    {
      code: "VC",
      alpha3Code: "VCT",
      currency: "XCD",
      name: "Saint Vincent and the Grenadines",
    },
    { code: "WS", alpha3Code: "WSM", currency: "WST", name: "Samoa" },
    { code: "SM", alpha3Code: "SMR", currency: "EUR", name: "San Marino" },
    {
      code: "ST",
      alpha3Code: "STP",
      currency: "STN",
      name: "Sao Tome and Principe",
    },
    { code: "SA", alpha3Code: "SAU", currency: "SAR", name: "Saudi Arabia" },
    { code: "SN", alpha3Code: "SEN", currency: "XOF", name: "Senegal" },
    { code: "RS", alpha3Code: "SRB", currency: "RSD", name: "Serbia" },
    { code: "SC", alpha3Code: "SYC", currency: "SCR", name: "Seychelles" },
    { code: "SL", alpha3Code: "SLE", currency: "SLL", name: "Sierra Leone" },
    { code: "SG", alpha3Code: "SGP", currency: "SGD", name: "Singapore" },
    { code: "SK", alpha3Code: "SVK", currency: "EUR", name: "Slovakia" },
    { code: "SI", alpha3Code: "SVN", currency: "EUR", name: "Slovenia" },
    { code: "SB", alpha3Code: "SLB", currency: "SBD", name: "Solomon Islands" },
    { code: "SO", alpha3Code: "SOM", currency: "SOS", name: "Somalia" },
    { code: "ZA", alpha3Code: "ZAF", currency: "ZAR", name: "South Africa" },
    { code: "SS", alpha3Code: "SSD", currency: "SSP", name: "South Sudan" },
    { code: "ES", alpha3Code: "ESP", currency: "EUR", name: "Spain" },
    { code: "LK", alpha3Code: "LKA", currency: "LKR", name: "Sri Lanka" },
    { code: "SD", alpha3Code: "SDN", currency: "SDG", name: "Sudan" },
    { code: "SR", alpha3Code: "SUR", currency: "SRD", name: "Suriname" },
    { code: "SZ", alpha3Code: "SWZ", currency: "SZL", name: "Swaziland" },
    { code: "SE", alpha3Code: "SWE", currency: "SEK", name: "Sweden" },
    { code: "CH", alpha3Code: "CHE", currency: "CHF", name: "Switzerland" },
    { code: "SY", alpha3Code: "SYR", currency: "SYP", name: "Syria" },
    { code: "TW", alpha3Code: "TWN", currency: "TWD", name: "Taiwan" },
    { code: "TJ", alpha3Code: "TJK", currency: "TJS", name: "Tajikistan" },
    { code: "TZ", alpha3Code: "TZA", currency: "TZS", name: "Tanzania" },
    { code: "TH", alpha3Code: "THA", currency: "THB", name: "Thailand" },
    { code: "TG", alpha3Code: "TGO", currency: "XOF", name: "Togo" },
    { code: "TO", alpha3Code: "TON", currency: "TOP", name: "Tonga" },
    {
      code: "TT",
      alpha3Code: "TTO",
      currency: "TTD",
      name: "Trinidad and Tobago",
    },
    { code: "TN", alpha3Code: "TUN", currency: "TND", name: "Tunisia" },
    { code: "TR", alpha3Code: "TUR", currency: "TRY", name: "Turkey" },
    { code: "TM", alpha3Code: "TKM", currency: "TMT", name: "Turkmenistan" },
    { code: "TV", alpha3Code: "TUV", currency: "AUD", name: "Tuvalu" },
    { code: "UG", alpha3Code: "UGA", currency: "UGX", name: "Uganda" },
    { code: "UA", alpha3Code: "UKR", currency: "UAH", name: "Ukraine" },
    {
      code: "AE",
      alpha3Code: "ARE",
      currency: "AED",
      name: "United Arab Emirates",
    },
    { code: "GB", alpha3Code: "GBR", currency: "GBP", name: "United Kingdom" },
    { code: "US", alpha3Code: "USA", currency: "USD", name: "United States" },
    { code: "UY", alpha3Code: "URY", currency: "UYU", name: "Uruguay" },
    { code: "UZ", alpha3Code: "UZB", currency: "UZS", name: "Uzbekistan" },
    { code: "VU", alpha3Code: "VUT", currency: "VUV", name: "Vanuatu" },
    { code: "VE", alpha3Code: "VEN", currency: "VEF", name: "Venezuela" },
    { code: "VN", alpha3Code: "VNM", currency: "VND", name: "Vietnam" },
    { code: "YE", alpha3Code: "YEM", currency: "YER", name: "Yemen" },
    { code: "ZM", alpha3Code: "ZMB", currency: "ZMW", name: "Zambia" },
    { code: "ZW", alpha3Code: "ZWE", currency: "ZWL", name: "Zimbabwe" },
  ];

export default countries;
