import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { booking_cancle, third_party, AIR_4_URL, AIR_CANCELLATION, siteconfig } from "../../../../API/endpoints";
import Ticket_Details from '../../../../widget/ticket_details';
import { post } from '../../../../API/airline'; // Assuming you have an API helper for making requests
import { toast } from "react-toastify";
import { post as apipost, get } from "../../../../API/apiHelper";
const BookingWidget = ({ data }) => {

    let bookingdata = null;
    if (data.Ticket_Details) {
        bookingdata = JSON.parse(data.Ticket_Details);
    }

    const [cancelReason, setCancelReason] = useState('');
    const [cancellationType, setCancellationType] = useState(""); // State for CancellationType
    const [cancelCode, setCancelCode] = useState("");
    const [Upiid, setUpiid] = useState(null);

    const [setting, setSettings] = useState(null);
    let isuat = "";
    

    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false); // New state for cancel confirmation modal

    const toggleModal = () => setShowModal(!showModal);
    const toggleCancelModal = () => setShowCancelModal(!showCancelModal); // Toggle cancel modal visibility
    const [userData, setUserData] = useState(null);
    const fetchSettings = async () => {
        try {
            const res = await get(siteconfig, true);
            const response = await res.json();
            setSettings(response.data);
            if (response.data) {
              if (response.data.etrav_api_prod_on === 1) {
                isuat = "no";
              } else {
                isuat = "yes";
              }
            }
        } catch (error) {
            console.log(error)
        }
    };


    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession && userDataFromSession != null) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }
        fetchSettings()
    }, [data]);


    const [isScanModalVisible, setIsScanModalVisible] = useState(false);
    const toggleScanModal = () => {
        setIsScanModalVisible(!isScanModalVisible);
    };


    const QrCodeComponent = ({ value }) => {
        const ref_no = btoa(value.Booking_RefNo);
        const currentUrl = window.location.origin;
        const dataToEncode = `${currentUrl}/Download_ticket/${ref_no}`;
        return (
            <div>
                <QRCodeCanvas value={dataToEncode} size={100} level="M" />
                <p style={{ marginTop: '10px' }}>Scan for Ticket</p>
            </div>
        );
    };

    // Function to handle booking cancellation
    const handleCancelBooking = async ({ cancellationType, cancelCode, cancelReason }) => {

        try {
            if (!cancellationType.trim()) {
                toast.error("Please select a Cancellation Type.");
                return;
            }
            if (!cancelCode.trim()) {
                toast.error("Please select a Cancellation Code.");
                return;
            }
            if (!cancelReason.trim()) {
                toast.error('Please provide a reason for cancellation.');
                return;
            }

            if (data.paying_method == 'Rezorpay') {
                if (!Upiid) {
                    toast.error('Please enter UPI ID');
                    return;
                }
            }


            let AirTicketCancelDetails = [];
            bookingdata.AirPNRDetails.forEach((details, index) => {
                AirTicketCancelDetails.push({
                    "FlightId": details.Flights[0].Flight_Id,
                    "PassengerId": "1",
                    "SegmentId": details.Flights[0].Segments[0].Segment_Id,
                });
            });
const setttingDataFromSession = sessionStorage.getItem("settting");
if (setttingDataFromSession) {
  const setttingData = JSON.parse(setttingDataFromSession);
if (setttingData.etrav_api_prod_on === 1) {
  isuat = "no";
} else {
  isuat = "yes";
}
}
            const payload = {
                // "Auth_Header": {
                //     "UserId": "viviantravelsuat",
                //     "Password": "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
                //     "IP_Address": "12333333",
                //     "Request_Id": "5500833959053023879",
                //     "IMEI_Number": "9536615000"
                // },
                "api_c": "a",
                "is_uat": isuat,
                "AirTicketCancelDetails": AirTicketCancelDetails,
                "Airline_PNR": "",
                "RefNo": data.Booking_RefNo,
                "CancelCode": cancelCode,
                "ReqRemarks": cancelReason,
                "CancellationType": parseInt(cancellationType, 10),
            }

            const api_url = await AIR_4_URL() + AIR_CANCELLATION;
            const res = await post(third_party, JSON.stringify(payload), api_url);
            const Apires = await res.json();
            if (Apires.data.Response_Header.Error_Desc == "SUCCESS") {

                const info = {
                    Booking_RefNo: data.Booking_RefNo,
                    description: cancelReason,
                    upi: (Upiid) ? (Upiid) : null,
                    type: (Upiid) ? 'Online' : 'Wallet',
                    amount: data.Amount,
                    user_id: userData.id,
                    cancel_api_type: 'Etrav',
                }

                const response = await apipost(booking_cancle, info, true); // Replace with actual endpoint
                if (response.ok) {
                    toast.success('Booking cancelled successfully.');
                    setShowCancelModal(false);
                } else {
                    toast.error('Failed to cancel booking.');
                }
            } else {
                return alert(Apires.data.Response_Header.Error_Desc + ' ' + Apires.data.Response_Header.Error_InnerException)
            }
        } catch (error) {
            console.error('Cancellation error:', error.message);
            alert('An error occurred during cancellation.');
        }
    };


    const [qrshowModal, setShowqrModal] = useState(false);
    const qrtoggleModal = () => setShowqrModal(!qrshowModal); // Toggle cancel modal visibility
    const cancellationOptions = {
        0: [
            { code: "015", description: "Please cancel my ticket with Applicable Penalty" },
            { code: "005", description: "I cancelled the ticket directly with Airline" },
            { code: "001", description: "Other - Mentioned the reason in Request Remarks" },
        ],
        1: [
            { code: "016", description: "Flight Schedule Changed by Airline" },
            { code: "005", description: "I cancelled the ticket directly with Airline" },
            { code: "004", description: "Received a communication from Airline that Flight is not Operational" },
            { code: "008", description: "Flight Cancelled by the Airline" },
            { code: "001", description: "Other - Mentioned the reason in Request Remarks" },
        ],
        2: [
            { code: "011", description: "Claim No Show Tax Refund" },
            { code: "001", description: "Other - Mentioned the reason in Request Remarks" },
        ],
    };
    const handleCancellationTypeChange = (e) => {
        setCancellationType(e.target.value);
        setCancelCode("");
    };

    return (
        <>
            {bookingdata && (
                <>
                    <tr>
                        <td>{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Airline_Name} ({bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Airline_Code})</td>
                        <td>{(data.Agency_RefNo) ? (data.Agency_RefNo) : 'N/A'}</td>
                        <td>{bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Origin} / {bookingdata.AirPNRDetails[0].Flights[0].Segments[0].Destination}</td>
                        <td><div className="">
                            {bookingdata.AirPNRDetails[0].PAXTicketDetails.map((Passanger, index) => (
                                <h6 className="mb-0" key={index}>{Passanger.Title} {Passanger.First_Name} {Passanger.Last_Name}</h6>
                            ))}
                        </div></td>
                        <td>{(bookingdata.Booking_Type == 0) ? 'One Way' : (bookingdata.Booking_Type == 1) ? 'Round-trip' : 'Multi-city'}</td>
                        <td>
                            ₹{data.Amount}
                        </td>
                        <td>
                            {data.status}
                        </td>
                        <td>
                            <div className="action-buttons d-flex gap-2">

                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm action-button"
                                    onClick={toggleModal}
                                >
                                    <i className="fa fa-ticket m-1"></i> <span className="button-text">Show Ticket</span>
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-success btn-sm action-button"
                                    onClick={qrtoggleModal}
                                >
                                    <i className="fa fa-qrcode m-1"></i> <span className="button-text">Ticket QR</span>
                                </button>

                                {data.status !== "cancelled" && (
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm action-button"
                                        onClick={toggleCancelModal}>
                                        <i className="fa fa-times-circle m-1"></i>{' '}
                                        <span className="button-text">Cancel Ticket</span>
                                    </button>
                                )}
                            </div>
                        </td>
                    </tr>

                    {/* Show Ticket Modal */}
                    <Modal show={showModal} onHide={toggleModal} size="xl" backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Ticket Details</Modal.Title>
                        </Modal.Header>
                        <Ticket_Details reference_id={bookingdata.Booking_RefNo} />
                    </Modal>

                    {/* Cancel Confirmation Modal */}

                    <Modal show={showCancelModal} onHide={toggleCancelModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel Booking</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to cancel this booking?</p>
                            <br />
                            <div className="mb-3">
                                <label htmlFor="cancellationType" className="form-label">Cancellation Type</label>
                                <select
                                    id="cancellationType"
                                    className="form-control"
                                    value={cancellationType}
                                    onChange={handleCancellationTypeChange}
                                >
                                    <option value="">Select Cancellation Type</option>
                                    <option value="0">Normal Cancel</option>
                                    <option value="1">Full Refund</option>
                                    <option value="2">No Show</option>
                                </select>
                            </div>
                            {cancellationType && cancellationOptions[cancellationType]?.length > 0 && (
                                <div className="mb-3">
                                    <label htmlFor="cancelCode" className="form-label">Cancellation Code</label>
                                    <select
                                        id="cancelCode"
                                        className="form-control"
                                        value={cancelCode}
                                        onChange={(e) => setCancelCode(e.target.value)}
                                    >
                                        <option value="">Select Cancellation Code</option>
                                        {cancellationOptions[cancellationType].map((option) => (
                                            <option key={option.code} value={option.code}>
                                                {option.description}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}


                            <div className="mb-3">
                                <label htmlFor="cancelReason" className="form-label">Cancellation Reason</label>
                                <textarea
                                    id="cancelReason"
                                    className="form-control"
                                    placeholder="Please provide a reason for cancellation (optional)"
                                    rows="2"
                                    value={cancelReason}
                                    onChange={(e) => setCancelReason(e.target.value)}
                                ></textarea>
                            </div>

                            {
                                (data.paying_method == 'Rezorpay') ? (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="cancelReason" className="form-label">Enter UPI ID To Receving Payment</label>
                                            <input
                                                type="text"
                                                name="uipid"
                                                onChange={(e) => setUpiid(e.target.value)}
                                                placeholder="Enter UPI ID here"
                                                className="form-control wizard-required"
                                                required
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="mb-3">
                                            <h4>Amount will be  receive in your wallet after cancel process</h4>
                                        </div>
                                    </>
                                )
                            }

                            {/* <div className="mb-3">
                                <label htmlFor="cancelCode" className="form-label">Cancellation Code</label>
                                <select
                                    id="cancelCode"
                                    className="form-control"
                                    // value={cancelCode}
                                    onChange={(e) => setCancelCode(e.target.value)}
                                >
                                    <option value="">Select Cancellation Code</option>
                                    <option value="">Select Cancellation Code</option>
                                    <option value="">Select Cancellation Code</option>
                                </select>
                            </div> */}


                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={toggleCancelModal}>
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleCancelBooking({ cancellationType, cancelCode, cancelReason })}
                            >
                                Confirm Cancel
                            </button>
                        </Modal.Footer>
                    </Modal>

                    {/* qr Modal */}
                    <Modal show={qrshowModal} onHide={qrtoggleModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Ticket Qr</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <QrCodeComponent value={bookingdata} />
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={qrtoggleModal}>Close</button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
            }
        </>
    );
};

export default BookingWidget;
