import React, { useState } from 'react';
import '../flight-listing/flight-listing.css'
import TitleBanner from '../flight-booking/title-ban'
import FlightBookingMain from '../flight-booking/flight-booking-main/flight-booking-main'
import { Link, useNavigate } from "react-router-dom";


const Flight_Booking = ({ data, traveltype, tripinfo, onUpdate, airlines }) => {
      const navigate = useNavigate();

      const userDataFromSession = sessionStorage.getItem("userData");
      if (!userDataFromSession) {
        navigate("/login");
      }
  return (
    <div className="flight-bookingss">
      <FlightBookingMain
        data={data}
        traveltype={traveltype}
        tripinfo={tripinfo}
        onUpdate={(updatedItem) => {
          onUpdate({});
        }}
        airlines={airlines}
      />
    </div>
  );
};

export default Flight_Booking