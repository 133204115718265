import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Progress from "../../../component/Loading";
import Ticket_Details from '../../../widget/ticket_details';
import { useNavigate } from "react-router-dom";

const Ticket = () => {
const navigate = useNavigate();
const userDataFromSession = sessionStorage.getItem("userData");
if (!userDataFromSession) {
 
  navigate("/login");
}
    const { id } = useParams();
    const reference_id = atob(id);

    return (
        <div className="border mb-4 card">

            <Ticket_Details reference_id={reference_id} />

        </div>
    );
};

export default Ticket;
