import React, { useState } from 'react';
import TitleBannerRP from '../refundpolicy/title-ban-refundpolicy'
import RefundPolicyContent from '../refundpolicy/refundpolicycontent'



const RefundPolicy = () => {
    return (
        <div>
            <TitleBannerRP />
            <RefundPolicyContent />
        </div>
    )
}

export default RefundPolicy