import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../flight-booking/flight-booking-main/flight-booking-main.css";
import "../flight-listing/flight-listing.css";
import "../flight-listing/flight-listing-main.css";
import "../flight-listing/booking-area-listing.css";
import TitleBanner from "../flight-listing/title-ban";
import icon2 from "../../assets/images/flight_icon/icon-2.png";
import route_plane from "../../assets/images/icon/route-plan.png";
import { Box, Typography, Container } from "@mui/material";
import { FaPlaneSlash } from "react-icons/fa";
import NoFlightsSVG from "../../assets/images/plane.png";
import { post } from "../../API/airline";
import Airlogo from '../../widget/air_logo'

import { get, post as apipost, formatDatetime, formatDate } from "../../API/apiHelper";
import {
  AIR_SEARCH,
  third_party,
  AIR_2_URL,
  country_list,
  siteconfig,
  AIR_FARERULE,
  airline_code,
} from "../../API/endpoints";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AutoCompleteDropdown from "../../widget/custom-dropdown/custom-dropdown";
import Progress from "../../component/Loading";
import { MdOutlineDelete } from "react-icons/md";
import FlightBooking from "../flight-booking/flight-booking";
import { Modal } from "react-bootstrap";

const FlightItem = ({
  flight,
  index,
  toggleExpand,
  expanded,
  searchkey,
  othercharges,
  adultcount,
  currenttab,
  onUpdate,
  onUpdatenew,
  airlinelist,
  selectedlist,
}) => {

  const departureDateTime = flight.Segments[0].Departure_DateTime;
  const dtime = new Date(departureDateTime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const arrivalDateTime = flight.Segments[0].Arrival_DateTime;
  const aTime = new Date(arrivalDateTime).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
// console.log(`selectedlist ${JSON.stringify(selectedlist)}`);
  // const [checkedStatesPerTab, setCheckedStatesPerTab] = useState({});
  const handleCheckboxChange = (e, tabIndex, selectedIndex, fareId, item) => {
    // let updatedCheckedStates = { ...checkedStatesPerTab };
    // updatedCheckedStates[tabIndex] = new Array(flight.Fares.length).fill(false);
    // if(checkedStatesPerTab[currenttab]?.[index];)
    // checkedStatesPerTab[currenttab] = checkedStatesPerTab[currenttab]?.map(
    //   () => false
    // );
    // for (let index = 0; index < updatedCheckedStates.length; index++) {
    //   for (let i = 0; i < updatedCheckedStates[index].length; i++) {
    //     updatedCheckedStates[index][i] = false;
    //   }
    // }
    // updatedCheckedStates[tabIndex][selectedIndex] = true;
    // setCheckedStatesPerTab(updatedCheckedStates);

    onUpdatenew({
      ischecked: e.target.checked,
      fareid: fareId,
      item: item,
      flight: flight,
      Search_Key: searchkey,
      charges: othercharges,
      currenttab: currenttab,
      adultcount: adultcount,
    });
  };
  const [showTooltip, setShowTooltip] = useState(false);
  let agencycharge = 0;
  const settingFromSessionup = sessionStorage.getItem("settting");
  if (settingFromSessionup) {
    let settting = JSON.parse(settingFromSessionup);
    agencycharge = settting.flight_agency_charge || 0;
  }

  return (
    <div className="meri_marji" key={index}>
      <div className="flight-block bg-white light-shadow p-16 br-10 mb-16">
        <div className="flight-area">
          <div className="airline-name">
            <Airlogo
              airCode={flight.Airline_Code}
              type={0}
              airlinelist={airlinelist}
            />
            <div>
              <h5 className="lightest-black mb-8">
                {flight?.Segments.at(0).Airline_Name}
              </h5>
              <h6 className="dark-gray">{flight?.Flight_Numbers}</h6>
            </div>
          </div>
          <div className="flight-detail">
            <div className="flight-departure">
              <h5 className="color-black">{dtime}</h5>
              <h5 className="dark-gray text-end">
                {flight?.Segments.filter(
                  (segment) => segment.Leg_Index === currenttab
                )?.at(0).Origin_City || ""}
              </h5>
            </div>
            <div className="d-inline-flex align-items-center gap-8">
              <span>To</span>
              <div className="from-to text-center">
                <h5 className="dark-gray">
                  {flight?.Segments.filter(
                    (segment) => segment.Leg_Index === currenttab
                  )?.at(-1).Duration || ""}
                </h5>
                <img
                  className="f_icon_list"
                  src={route_plane}
                  alt="route-plan"
                />
                <h6 className="color-black">
                  {flight?.Segments.filter(
                    (segment) => segment.Leg_Index === currenttab
                  )?.at(-1).Stop_Over || ""}{" "}
                  Stop
                </h6>
              </div>
              <span>From</span>
            </div>
            <div className="flight-departure">
              <h5 className="color-black">{aTime}</h5>
              <h5 className="dark-gray">
                {flight?.Segments.filter(
                  (segment) => segment.Leg_Index === currenttab
                )?.at(-1).Destination_City || ""}
              </h5>
            </div>
          </div>
          <div className="flight-button"></div>
        </div>
        <hr className="bg-light-gray mt-16 mb-16" />
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="color-black">{formatDate(flight.TravelDate)}</h5>
          <div>
            <button
              className="accordion-button color-primary h5 collapsed"
              onClick={() => toggleExpand(index)}
            >
              <i
                className={`fal fa-chevron-${
                  expanded ? "up" : "down"
                } color-primary`}
              ></i>
              &nbsp; View Fares ({flight.Fares.length})
            </button>
          </div>
        </div>
      </div>
      {expanded &&
        flight.Fares.map((item, index) => (
          <>
            <div className="sub-listing flight-block bg-white light-shadow p-16 br-10 mb-16">
              <div className="d-flex justify-content-between align-items-center p-8 highlight-section">
                <div>
                  <h6
                    className={`color-black mb-2 ${
                      item.Refundable ? "refundsit" : "nonrefundsit"
                    }`}
                  >
                    {item.Refundable ? "Refundable" : "Non-Refundable"}
                  </h6>

                  <h6
                    className={`color-white ${
                      item.Refundable ? "refundsit" : "nonrefundsit"
                    }`}
                    style={{ background: "#5353e1" }}
                  >
                    {flight.Segments.length === 1
                      ? "Non Stop"
                      : flight.Segments.length === 2
                      ? "1 Stop"
                      : "2+ Stop"}{" "}
                  </h6>
                </div>
                <div>
                  <h6 className="color-black">
                    Seats Available: {item.Seats_Available}
                  </h6>{" "}
                  <h6 className="color-black">
                    Check In Baggage:{" "}
                    {item.FareDetails[0].Free_Baggage.Check_In_Baggage}
                  </h6>
                  <h6 className="color-black">
                    Hand Baggage:{" "}
                    {item.FareDetails[0].Free_Baggage.Hand_Baggage}
                  </h6>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0 text-muted">
                    {item.FareDetails[0].FareClasses[0].Class_Desc} (
                    {item.FareDetails[0].FareClasses[0].Class_Code}):
                  </p>
                  <p className="mb-0">
                    {item.FareDetails[0].FareClasses[0].FareBasis}
                  </p>
                </div>
                {/* <div>
                  <h6 className="color-black">Class: {item.ProductClass}</h6>
                </div> */}
              </div>

              <hr className="bg-light-gray mt-8 mb-8" />
              <div className="flight-area">
                <div className="airline-name">
                  <div>
                    <h5 className="lightest-black mb-8">
                      {flight.Segments[0].Airline_Name}
                    </h5>
                    <h6 className="dark-gray">{flight.Flight_Numbers}</h6>
                  </div>
                </div>
                {/* <div className=""> */}
                <div className="row  mx-2">
                  {flight.Segments.filter(
                    (segment) => segment.Leg_Index === currenttab
                  ).map((segment, index) => (
                    <div className="flight-detail mt-4">
                      <div className="flight-departure">
                        <h5 className="color-black">
                          {formatDatetime(segment.Departure_DateTime || "")}
                        </h5>
                        <h5 className="dark-gray text-end">
                          {segment.Origin_City || ""}
                        </h5>
                      </div>
                      <div className="d-inline-flex align-items-center gap-8">
                        <span>To</span>
                        <div className="from-to text-center">
                          <h5 className="dark-gray">
                            {segment.Duration || ""}
                          </h5>
                          <img
                            className="f_icon_list"
                            src={route_plane}
                            alt="route-plane"
                          />
                          <h6 className="color-black">
                            {segment.Stop_Over || ""} Stop
                          </h6>
                        </div>
                        <span>From</span>
                      </div>
                      <div className="flight-departure">
                        <h5 className="color-black">
                          {formatDatetime(segment.Arrival_DateTime || "")}
                        </h5>
                        <h5 className="dark-gray">
                          {segment.Destination_City || ""}
                        </h5>
                      </div>
                    </div>
                  ))}
                  {/* </div> */}
                </div>

                <div className="flight-button">
                  <div
                    key={index}
                    className="amount amountsss"
                    onMouseEnter={() => setShowTooltip(index)} // Set the current index
                    onMouseLeave={() => setShowTooltip(null)} // Clear the tooltip
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="color-black">
                      ₹
                      {/* {item.FareDetails.reduce(
                        (acc, fare) => acc + Number(fare.Total_Amount),
                        0
                      )} */}
                      {Number(
                        item.FareDetails.find((fare) => fare.PAX_Type === 0)
                          ?.Total_Amount || 0
                      ) *
                        Number(adultcount.adult || 0) +
                        // Number(Details.charges) +
                        Number(
                          item.FareDetails.find((fare) => fare.PAX_Type === 1)
                            ?.Total_Amount || 0
                        ) *
                          Number(adultcount.child || 0) +
                        Number(
                          item.FareDetails.find((fare) => fare.PAX_Type === 2)
                            ?.Total_Amount || 0
                        ) *
                          Number(adultcount.infant || 0) +
                        Number(agencycharge)}
                    </h5>
                    <h6 className="dark-gray text-end">Price</h6>

                    {showTooltip === index && ( // Show tooltip only for the hovered item
                      <div className="tooltip-cstmsit">
                        {adultcount.adult > 0 && (
                          <>
                            Adult = ₹
                            {Number(
                              item.FareDetails.find(
                                (fare) => fare.PAX_Type === 0
                              )?.Total_Amount
                            ) * Number(adultcount.adult) || "N/A"}
                            <br />
                          </>
                        )}
                        {adultcount.child > 0 && (
                          <>
                            Child = ₹
                            {Number(
                              item.FareDetails.find(
                                (fare) => fare.PAX_Type === 1
                              )?.Total_Amount
                            ) * Number(adultcount.child) || "N/A"}
                            <br />
                          </>
                        )}
                        {adultcount.infant > 0 && (
                          <>
                            Infant = ₹
                            {Number(
                              item.FareDetails.find(
                                (fare) => fare.PAX_Type === 2
                              )?.Total_Amount
                            ) * Number(adultcount.infant) || "N/A"}
                            <br />
                          </>
                        )}
                        {Number(agencycharge) > 0 && (
                          <>Charge = ₹{Number(agencycharge)}</>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="booknowcheckbox">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        checked={
                          selectedlist.find(
                            (sitem) => sitem.fareid === item.Fare_Id
                          )
                          // checkedStatesPerTab[currenttab]?.[index] || false
                        }
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            currenttab,
                            index,
                            item.Fare_Id,
                            item
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <hr className="bg-light-gray mt-16 mb-16" />
            </div>
            <hr style={{ border: "none", height: "20px" }} />
          </>
        ))}
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="paginations mt-8">
      <ul className="unstyled">
        {/* Previous Button */}
        <li className="arrow">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            className="h4 fw-600 mb-0"
            disabled={currentPage === 1}
          >
            <i className="far fa-chevron-left"></i>
          </button>
        </li>

        {/* Current Page and Total Pages Display */}
        <li className="page-info">
          <span className="h6 fw-600 mb-0">
            Page {currentPage} of {totalPages}
          </span>
        </li>

        {/* Next Button */}
        <li className="arrow">
          <button
            onClick={() => onPageChange(currentPage + 1)}
            className="h4 fw-600 mb-0"
            disabled={currentPage === totalPages}
          >
            <i className="far fa-chevron-right"></i>
          </button>
        </li>
      </ul>
    </div>
  );
};

const FlightList = ({
  flights,
  skey,
  flightsPerPage,
  adult,
  selectedTab,
  onUpdate,
  onUpdatenew,
  airlinelist,
  selectedlist,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [configdata, setSettings] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await get(siteconfig, true);
        const response = await res.json();
        setSettings(response.data.flight_agency_charge);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSettings();
  }, []);

  const indexOfLastFlight = currentPage * flightsPerPage;
  const indexOfFirstFlight = indexOfLastFlight - flightsPerPage;
  const currentFlights = flights.slice(indexOfFirstFlight, indexOfLastFlight);
  const totalPages = Math.ceil(flights.length / flightsPerPage);

  return (
    <div>
      {currentFlights.map((flight, index) => (
        <FlightItem
          key={index}
          flight={flight}
          index={index}
          toggleExpand={toggleExpand}
          expanded={expandedIndex === index}
          searchkey={skey}
          othercharges={configdata ? configdata : "0"}
          adultcount={adult}
          currenttab={selectedTab}
          onUpdate={(updatedItem) => {
            onUpdate({
              ...updatedItem,
            });
          }}
          onUpdatenew={(data) => {
            onUpdatenew({
              ...data,
            });
          }}
          selectedlist={selectedlist}
          airlinelist={airlinelist}
        />
      ))}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

const Flight_listing = () => {
  const navigate = useNavigate();
 
     const userDataFromSession = sessionStorage.getItem("userData");
     if (!userDataFromSession) {
       navigate("/login");
     }
  const [loading, setLoding] = useState(null);
  const [lastName, setLastName] = useState("");
  const [returnDate, setReturnDate] = useState(null);
  const [multiCities, setMultiCities] = useState([
    { from: "", to: "", date: null },
  ]);
  const [activeTabStatus, setActiveTabStatus] = useState("byRoute");
  const [tripinfodetails, setTripinfo] = useState([]);
  const [currenttab, setTab] = useState(0);
  const [tripinfodata, setTripdata] = useState([]);
  const [setting, setSettings] = useState(null);
  let isuat = "";
  if (setting) {
    if (setting.etrav_api_prod_on == 1) {
      isuat = "no"
    } else {
      isuat = "yes"
    }
  }

  const handleTabClickStatus = (tabId) => {
    setActiveTabStatus(tabId);
  };

  const [depDate, setDepDate] = useState(null);
  const [passengers, setPassengers] = useState({
    adult: 0,
    child: 0,
    infant: 0,
  });
  
  const [activeTab, setActiveTab] = useState("flight");
  const [flightType, setFlightType] = useState("0");
  const [agencycharge, setAgencycharge] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({
    depFrom: "",
    arrivalTo: "",
    depDate: "",
    returnDate: "",
    promoCode: "",
  });
  const [arrivalTo, setArrivalTo] = useState("");
  const [departureFrom, setDepartureFrom] = useState("");
  const [showError, setShowError] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab == "status") {
      handleTabClickStatus("byRoute");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingDetails({ ...bookingDetails, [name]: value });
  };

  const handleFlightTypeChange = (e) => {
    setFlightType(e.target.value);
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const getNextDay = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  };

  const [flightsdata, setarraydata] = useState(null);
  const [flightsitem, setflightitem] = useState(null);
  const [searchlabel, setSearchlabel] = useState("Search Flight");
  const [tempflightData, setarraydata3] = useState(null);
  const [searchkey, setsearchkey] = useState(null);
  const [selectedAirline, setSelectedAirline] = useState("");
  const [selectedRefundable, setSelectedRefundable] = useState("");
  const [selectedstop, setSelectedstop] = useState("");
  const [refundnonrefund, setRefundnonrefund] = useState("");
  const [airpostlist, setAirpostlist] = useState([]);
  const [traveltype, settraveltype] = useState(0);
  const [airlines, setairlines] = useState([])

    
  useEffect(() => {
    fetchCountry();
    fetchSettings();
    get_airlines();
      const settingFromSessionup = sessionStorage.getItem("settting");
      if (settingFromSessionup) {
        let settting = JSON.parse(settingFromSessionup);
        setAgencycharge(settting.flight_agency_charge || 0);
      }
  }, []);
  const fetchCountry = async () => {
    try {
      const response = await apipost(
        country_list,
        { page: "0", limit: "50000" },
        true
      );
      const data = await response.json();
      const res = data.data.map((country) => ({
        code: country.alpha_2,
        name: country.country_name,
        country_id: country.country_id,
        display: `${country.alpha_2} - ${country.country_code}`,
      }));
      setAirpostlist(res);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchSettings = async () => {
    try {
      const res = await get(siteconfig, true);
      const response = await res.json();
      setSettings(response.data);
    } catch (error) {
      console.log(error)
    }
  };

  async function get_airlines() {
    try {
      const response = await post(airline_code, {}, true);
      const res = await response.json();
      setairlines(res.data.rows)
    } catch (error) {
      console.log(error)
    }
  }



  const handleClear = () => {
    setSelectedAirline("");
    setSelectedRefundable("");
    setSelectedstop("");
    setarraydata(tempflightData);
  };

  const handleFilter = (event, type) => {
    let Shortdata = [...tempflightData]; // Initialize Shortdata to a copy of tempflightData
    let airline = selectedAirline;
    let refund = selectedRefundable;
    let stop = selectedstop;
    if (type === "airline") {
      airline = event.target.value;
      setSelectedAirline(event.target.value);
    } else if (type === "refund") {
      refund = event.target.value;

      setSelectedRefundable(event.target.value);
    } else if (type === "stop") {
      stop = event.target.value;
      setSelectedstop(event.target.value);
    }
    // setTimeout(() => {
    if (airline !== "") {
      Shortdata = Shortdata.filter((flight) =>
        flight.Segments[0].Airline_Name.toLowerCase().includes(
          airline.toLowerCase()
        )
      );
    }

    if (refund !== "") {
      Shortdata = Shortdata.filter((flight) =>
        flight.Fares.some((fare) => fare.Refundable === (refund === "yes"))
      );
    }

    if (stop !== "") {
      if (stop === "nonstop") {
        Shortdata = Shortdata.filter((flight) => flight.Segments.length === 1);
      } else if (stop === "1stop") {
        Shortdata = Shortdata.filter((flight) => flight.Segments.length === 2);
      } else if (stop === "2stop") {
        Shortdata = Shortdata.filter((flight) => flight.Segments.length > 2);
      }
    }

    // if (type === "stop") {
    //   Shortdata = tempflightData.filter(
    //     (flight) =>
    //       event.target.value === "Non-Stop"
    //         ? flight.Segments[0].Stop_Over === null // Checking for Non-Stop flights
    //         : flight.Segments[0].Stop_Over !== null // Checking for flights with stopovers
    //   );
    // }

    // If no filter matches, return the original data
    // if (Shortdata.length === 0) {
    //     Shortdata = [...tempflightData];  // Reset to the original data
    // }

    setarraydata(Shortdata); // Update the state with filtered data
    // }, 1000);
  };

  let airlineCounts = [];
  if (tempflightData) {
    airlineCounts = tempflightData.reduce((acc, flight) => {
      const airlineName = flight.Segments[0].Airline_Name;
      acc[airlineName] = (acc[airlineName] || 0) + 1;
      return acc;
    }, {});
  }
  const [selectedOption, setSelectedOption] = useState(0);

  const options = ["ECONOMY", "BUSINESS", "FIRST", "PREMIUM_ECONOMY"];
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
toast.dismiss();
    setTab(0);
    const currentTotal =
      passengers.adult + passengers.child + passengers.infant;
    if (currentTotal == 0) {
      setLoding(false);
      return alert("Select Passengers First..");
    } else if (passengers.adult == 0) {
      setLoding(false);
      return alert("Select one adult please..");
    }else if (departureFrom === arrivalTo){
      setLoding(false);
            return alert("Select diffrent city to travel..");

    }
     setLoding(true);
    setflightitem(null);
    setTripdata([]);
    setarraydata([]);
    setarraydata3([]);
    Setselectedlistbooking([])
    Setselectedlist([]);
    let Trip_Info = [];
    if (flightType == 0) {
      Trip_Info = [
        {
          Origin: departureFrom,
          Destination: arrivalTo,
          TravelDate: depDate ? depDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' }) : null,
          Trip_Id: 0,
        },
      ];
    } else if (flightType == 1) {
      Trip_Info = [
        {
          Origin: departureFrom,
          Destination: arrivalTo,
          TravelDate: depDate ? depDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' }) : null,
          Trip_Id: 0,
        },
        {
          Origin: arrivalTo,
          Destination: departureFrom,
          TravelDate: returnDate
            ? returnDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })
            : null,
          Trip_Id: 1,
        },
      ];
    } else {
      Trip_Info = [
        {
          Origin: departureFrom,
          Destination: arrivalTo,
          TravelDate: depDate ? depDate.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' }) : null,
          Trip_Id: 0,
        },
      ];

      // Loop over each new trip and push it to Trip_Info in the specified format
      multiCities.forEach((trip, index) => {
        Trip_Info.push({
          Origin: trip.from,
          Destination: trip.to,
          TravelDate: trip.date
            ? `${String(trip.date.getMonth() + 1).padStart(2, "0")}/${String(
              trip.date.getDate()
            ).padStart(2, "0")}/${trip.date.getFullYear()}`
            : null,
          Trip_Id: index + 1, // Assuming Trip_Id starts from 1 for each new trip
        });
      });
    }

    const departureFromdata = airpostlist.find(
      (item) => item.code === departureFrom
    );
    const arrivalTodata = airpostlist.find((item) => item.code === arrivalTo);
    handleClear();
    if (!departureFromdata) {
      setLoding(false);
      return alert("Select vaild From");
    }
    if (!arrivalTodata) {
      setLoding(false);
      return alert("Select vaild To");
    }

    settraveltype(
      departureFromdata.country_id === arrivalTodata.country_id ? 0 : 1
    );

    const payload = {
      // Auth_Header: {
      //   UserId: "viviantravelsuat",
      //   Password: "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
      //   IP_Address: "0000000000000",
      //   Request_Id: "5500887959052",
      //   IMEI_Number: "2232323232323",
      // },
      api_c: "a",
      is_uat: isuat,
      Travel_Type: departureFromdata.country_id === arrivalTodata.country_id ? 0 : 1,
      Booking_Type: Number(flightType),
      TripInfo: Trip_Info,
      Adult_Count: passengers.adult,
      Child_Count: passengers.child,
      Infant_Count: passengers.infant,
      Class_Of_Travel: selectedOption,
      InventoryType: 0,
      Filtered_Airline: [
        {
          Airline_Code: "",
        },
      ],
    };

    const api_url = (await AIR_2_URL()) + AIR_SEARCH;
    const response = await post(third_party, JSON.stringify(payload), api_url);
    const data = await response.json();

    handleClear();
    if (data.status == false) {
      alert(data.message);
      
    } else {
      if (
        data.data.TripDetails&&data.data.TripDetails.length ===
          Trip_Info.length &&
        data.data.TripDetails
      ) {
        setTripinfo(Trip_Info);

        setSearchlabel("Modified Search");
        setTripdata(data.data.TripDetails);
        setsearchkey(data.data.Search_Key);

        setarraydata(data.data.TripDetails[0].Flights);
        setarraydata3(data.data.TripDetails[0].Flights);
      } else {
        alert(`No flights for this trip segment`);
        setTripinfo([]);
      }
    }
   setLoding(false);
    
  };

  const handlePassengerChange = (type, action) => {
    setPassengers((prevPassengers) => {
      const currentTotal =
        prevPassengers.adult + prevPassengers.child + prevPassengers.infant;

      // Calculate the new count for the type (e.g., adult, child, infant)
      const count = prevPassengers[type];
      const updatedCount = action === "increment" ? count + 1 : count - 1;

      // Allow increment only if the total passengers would be 9 or less
      if (action === "increment" && currentTotal >= 9) {
        return prevPassengers; // Do nothing if total is already 9 or more
      }

      // Ensure count does not go below 0
      return {
        ...prevPassengers,
        [type]: Math.max(0, updatedCount),
      };
    });
  };

  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event) => {
    // Check if the clicked area is outside the popup
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    // Add event listener when the popup is open
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const [isFundsExpanded, setFundsExpanded] = useState(true);
  const [isStopsExpanded, setStopsExpanded] = useState(true);
  const [isAirlinesExpanded, setAirlinesExpanded] = useState(true);
  const [isRefundableExpanded, setRefundableExpanded] = useState(true);
  const [isStopExpanded, setStopExpanded] = useState(true);
  const [showMoreAirlines, setShowMoreAirlines] = useState(false);
  const [classType, setClassType] = useState("economy");

  // const addCity = () => {
  //     setMultiCities([...multiCities, { from: "", to: "", date: null }]);
  // };

  const removeCity = (index) => {
    setMultiCities(multiCities.filter((_, i) => i !== index));
  };

  const handleCityChange = (index, field, value) => {
    let updatedCities = [...multiCities];
    if (updatedCities[index]) {
      updatedCities[index][field] = value;
      updatedCities[index]["from"] =
        index == 0 ? arrivalTo : multiCities[index - 1]?.to || "";
      setMultiCities(updatedCities);
    }
  };

  const addCity = () => {
    const lastCity = multiCities[multiCities.length - 1]; // Get the last city
    const newCity = {
      from: lastCity ? lastCity.from : "", // If there's no last city, set to empty
      to: lastCity ? lastCity.to : "", // Same for "To"
      date: lastCity ? lastCity.date : null, // Use the same date or null if there is no last city
    };
    setMultiCities([...multiCities, newCity]); // Add the new city
  };

  const today = new Date();
  const [reference, setReference] = useState("");
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  function openTab(index) {
    if (index === 0 || selectedlist.length >= index) {
      
      if (tripinfodata.length > index) {
        // setarraydata(tripinfodata[index].Flights);
        // handleFilter("","");
        let Shortdata = tripinfodata[index].Flights; // Initialize Shortdata to a copy of tempflightData
        let airline = selectedAirline;
        let refund = selectedRefundable;
        let stop = selectedstop;
        
        // setTimeout(() => {
        if (airline !== "") {
          Shortdata = Shortdata.filter((flight) =>
            flight.Segments[0].Airline_Name.toLowerCase().includes(
              airline.toLowerCase()
            )
          );
        }

        if (refund !== "") {
          Shortdata = Shortdata.filter((flight) =>
            flight.Fares.some((fare) => fare.Refundable === (refund === "yes"))
          );
        }

        if (stop !== "") {
          if (stop === "nonstop") {
            Shortdata = Shortdata.filter(
              (flight) => flight.Segments.length === 1
            );
          } else if (stop === "1stop") {
            Shortdata = Shortdata.filter(
              (flight) => flight.Segments.length === 2
            );
          } else if (stop === "2stop") {
            Shortdata = Shortdata.filter(
              (flight) => flight.Segments.length > 2
            );
          }
        }
        setarraydata(Shortdata);
        setarraydata3(tripinfodata[index].Flights);
      } else {
        setarraydata([]);
        setarraydata3([]);
      }
      setTab(index);
    } else {
      const msg = `Select Fair for ${tripinfodetails[index - 1].Origin} - ${
        tripinfodetails[index - 1].Destination
      }`;
      alert(msg);
    }
  }

  const [selectedlist, Setselectedlist] = useState([]);
  const [selectedlistbooking, Setselectedlistbooking] = useState([]);
  const [selectedlistloading, Setselectedlistloading] = useState(false);

  function setselectedlistin(params) {
    Setselectedlistloading(true);
    const oldfare = [...selectedlist];
    if (oldfare[params.currenttab] !== undefined) {
      oldfare.splice(params.currenttab, 1);
    }
    oldfare.push(params);
    Setselectedlist(oldfare);
    Setselectedlistloading(false);
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fareDetails, setFareDetails] = useState(null);

  const [fareloding, Setfareloading] = useState(false);
  const openModal = async (fareId, flightkey, searchkey) => {
    Setfareloading(true);
    const payload = {
      // Auth_Header: {
      //   UserId: "viviantravelsuat",
      //   Password: "3B96F16F32C9AEB30C4DF9B3FBABAE964E4E1033",
      //   IP_Address: "12333333",
      //   Request_Id: "5500887959052",
      //   IMEI_Number: "9536615000",
      // },
      api_c: "a",
      is_uat: isuat,
      Search_Key: searchkey,
      Flight_Key: flightkey,
      Fare_Id: fareId,
    };
    const api_url = (await AIR_2_URL()) + AIR_FARERULE;
    const response = await post(third_party, JSON.stringify(payload), api_url);
    const resp = await response.json();

    setFareDetails(resp.data.FareRules[0].FareRuleDesc);
    setModalIsOpen(true);
    Setfareloading(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFareDetails(null);
  };

  const [showTooltip, setShowTooltip] = useState(false);


  return (
    <div className="flight-listing">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <TitleBanner />

      <section className="booking mb-60">
        <div className="container">
          <div className="content">
            <div className="card">
              <div className="card-header"></div>
              <div className="card-body tab-content">
                {activeTab === "flight" && (
                  <form onSubmit={handleSubmit}>
                    <div className="custom-control mb-16">
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="way"
                          className="custom-control-input"
                          id="one"
                          value="0"
                          checked={flightType === "0"}
                          onChange={handleFlightTypeChange}
                        />
                        <label className="custom-control-label" htmlFor="one">
                          One way
                        </label>
                      </div>
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="way"
                          className="custom-control-input"
                          id="round"
                          value="1"
                          checked={flightType === "1"}
                          onChange={handleFlightTypeChange}
                        />
                        <label className="custom-control-label" htmlFor="round">
                          Round-trip
                        </label>
                      </div>
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="way"
                          className="custom-control-input"
                          id="multi"
                          value="3"
                          checked={flightType === "3"}
                          onChange={handleFlightTypeChange}
                        />
                        <label className="custom-control-label" htmlFor="multi">
                          Multi-City
                        </label>
                      </div>
                    </div>

                    {/* One-way and Round-trip Fields */}
                    {flightType !== "3" && (
                      <div className="row booking-info mb-16">
                        <div className="col-12 d-xl-flex align-items-center justify-content-between gap-16 d-block p-0">
                          <div className="custom-sel-input-block sitdrpdwn">
                            <label className="h6 color-medium-gray">From</label>
                            <AutoCompleteDropdown
                              placeholder="From"
                              value={departureFrom}
                              onChange={(inputValue) => {
                                setDepartureFrom(inputValue);
                              }}
                              showError={showError}
                            />
                          </div>
                          <div
                            className="arrows"
                            onClick={() => {
                              const temp = departureFrom;
                              setDepartureFrom(arrivalTo);
                              setArrivalTo(temp);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_518_2277)">
                                <path
                                  d="M23.6804 6.07409L18.2259 0.619583C17.7999 0.193537 17.1092 0.193537 16.6831 0.619583C16.2571 1.04563 16.2571 1.73628 16.6831 2.16233L20.2754 5.75464H1.09096C0.488472 5.75464 3.51626e-05 6.24307 3.51626e-05 6.84556C3.51626e-05 7.44804 0.488472 7.93648 1.09096 7.93648H20.2754L16.6832 11.5287C16.2571 11.9548 16.2571 12.6455 16.6832 13.0715C16.8961 13.2845 17.1753 13.391 17.4545 13.391C17.7337 13.391 18.0129 13.2845 18.2258 13.0714L23.6804 7.61688C24.1064 7.19084 24.1064 6.50013 23.6804 6.07409Z"
                                  fill="#ffa85d"
                                />
                                <path
                                  d="M22.9091 16.6637H3.72462L7.31683 13.0714C7.74288 12.6453 7.74288 11.9547 7.31683 11.5286C6.89088 11.1026 6.20013 11.1026 5.77409 11.5286L0.319535 16.9831C-0.106512 17.4092 -0.106512 18.0998 0.319535 18.5259L5.77404 23.9804C5.98713 24.1935 6.26627 24.3 6.54546 24.3C6.82465 24.3 7.10388 24.1935 7.31679 23.9804C7.74283 23.5544 7.74283 22.8637 7.31679 22.4377L3.72457 18.8455H22.9091C23.5116 18.8455 24 18.357 24 17.7546C24 17.1521 23.5116 16.6637 22.9091 16.6637Z"
                                  fill="#ffa85d"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_518_2277">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="custom-sel-input-block">
                            <label className="h6 color-medium-gray">To</label>
                            <AutoCompleteDropdown
                              placeholder="To"
                              value={arrivalTo}
                              onChange={(inputValue) => {
                                setArrivalTo(inputValue);
                              }}
                              showError={showError}
                            />
                          </div>

                          <div className="col-12 col-xl-3 d-xl-flex align-items-center gap-16">
                            <div className="input-date-picker">
                              <label
                                htmlFor="dep"
                                className="h6 color-medium-gray"
                              >
                                Departure
                              </label>
                              <DatePicker
                                selected={depDate}
                                onChange={(date) => setDepDate(date)}
                                placeholderText="dd-MMM-yyyy"
                                dateFormat="dd-MMM-yyyy"
                                className="sel-input date_from"
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                required
                                minDate={new Date(today)}
                                showMonthDropdown={false} // Disable month dropdown
                                showYearDropdown={false} // Disable year dropdown
                                maxDate={
                                  new Date(
                                    new Date().setFullYear(
                                      new Date().getFullYear() + 10
                                    )
                                  )
                                }
                              />
                            </div>
                            {flightType === "1" && (
                              <div className="input-date-picker">
                                <label
                                  htmlFor="ret"
                                  className="h6 color-medium-gray"
                                >
                                  Return Date
                                </label>
                                <DatePicker
                                  selected={returnDate}
                                  onChange={(date) => setReturnDate(date)}
                                  placeholderText="dd-MMM-yyyy"
                                  dateFormat="dd-MMM-yyyy"
                                  className="sel-input date_from"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    cursor: "pointer",
                                  }}
                                  required
                                  minDate={
                                    depDate
                                      ? new Date(depDate.getTime())
                                      : new Date()
                                  }
                                  showMonthDropdown={false} // Disable month dropdown
                                  showYearDropdown={false} // Disable year dropdown
                                  maxDate={
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() + 10
                                      )
                                    )
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-xl-4 d-xl-flex align-items-center gap-16">
                            <div className="custom-sel-input-block">
                              <div className="h6 color-medium-gray">
                                Passengers No.
                              </div>
                              <div
                                className="seat-booking color-black"
                                onClick={togglePopup}
                              >
                                <span className="total-passenger">
                                  {passengers.adult +
                                    passengers.child +
                                    passengers.infant}{" "}
                                  Passengers
                                </span>
                              </div>

                              {showPopup && (
                                <div
                                  ref={popupRef}
                                  className="passenger-area pessenger-box bg-white light-shadow br-5 p-24"
                                  style={{ display: "block" }}
                                >
                                  <h4 className="color-black mb-32">
                                    Passengers
                                  </h4>
                                  <div className="passenger-box mb-24">
                                    <div className="row">
                                      <div className="col-md-7 col-sm-6 col-6">
                                        <div className="content-box">
                                          <h5 className="lightest-black">
                                            Adult
                                          </h5>
                                          <p className="color-medium-gray light">
                                            {" "}
                                            Above 12 years.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-5 col-sm-6 col-6">
                                        <div className="quantity quantity-wrap">
                                          <button
                                            className="decrement"
                                            type="button"
                                            onClick={() =>
                                              handlePassengerChange(
                                                "adult",
                                                "decrement"
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            className="number"
                                            type="text"
                                            value={passengers.adult}
                                            readOnly
                                            required
                                          />
                                          <button
                                            className="increment"
                                            type="button"
                                            onClick={() =>
                                              handlePassengerChange(
                                                "adult",
                                                "increment"
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="passenger-box mb-24">
                                    <div className="row">
                                      <div className="col-md-7 col-sm-6 col-6">
                                        <div className="content-box">
                                          <h5 className="lightest-black">
                                            Child
                                          </h5>
                                          <p className="color-medium-gray light">
                                            {" "}
                                            2-12 years on travel date.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-5 col-sm-6 col-6">
                                        <div className="quantity quantity-wrap">
                                          <button
                                            className="decrement"
                                            type="button"
                                            onClick={() =>
                                              handlePassengerChange(
                                                "child",
                                                "decrement"
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            className="number"
                                            type="text"
                                            value={passengers.child}
                                            readOnly
                                          />
                                          <button
                                            className="increment"
                                            type="button"
                                            onClick={() =>
                                              handlePassengerChange(
                                                "child",
                                                "increment"
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="passenger-box mb-24">
                                    <div className="row">
                                      <div className="col-md-7 col-sm-6 col-6">
                                        <div className="content-box">
                                          <h5 className="lightest-black">
                                            Infant
                                          </h5>
                                          <p className="color-medium-gray light">
                                            {" "}
                                            Below 2 years.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-5 col-sm-6 col-6">
                                        <div className="quantity quantity-wrap">
                                          <button
                                            className="decrement"
                                            type="button"
                                            onClick={() =>
                                              handlePassengerChange(
                                                "infant",
                                                "decrement"
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <input
                                            className="number"
                                            type="text"
                                            value={passengers.infant}
                                            readOnly
                                          />
                                          <button
                                            className="increment"
                                            type="button"
                                            onClick={() =>
                                              handlePassengerChange(
                                                "infant",
                                                "increment"
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="color-medium-gray light mb-32">
                                    Please note: You can book for a maximum of
                                    nine passengers.
                                  </p>
                                </div>
                              )}
                            </div>

                            <div className="custom-sel-input-block">
                              <div className="h6 color-medium-gray">
                                Class Type
                              </div>
                              <select
                                className="sel-input"
                                value={selectedOption}
                                onChange={(e) =>
                                  handleOptionClick(e.target.value)
                                }
                                style={{
                                  width: "100%",
                                  cursor: "pointer",
                                }}
                              >
                                {options.map((option, index) => (
                                  <option key={index} value={index}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {flightType === "3" && (
                      <div>
                        <div className="row booking-info mb-8">
                          <div className="col-12 d-xl-flex align-items-center justify-content-between gap-16 d-block p-0">
                            <div className="col-12 col-xl-5 d-xl-flex align-items-center gap-16">
                              <div className="custom-sel-input-block">
                                <label className="h6 color-medium-gray">
                                  From
                                </label>
                                <AutoCompleteDropdown
                                  placeholder="From"
                                  value={departureFrom}
                                  onChange={(inputValue) => {
                                    setDepartureFrom(inputValue);
                                  }}
                                  showError={showError}
                                />
                              </div>
                              <div className="arrows">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g clipPath="url(#clip0_518_2277)">
                                    <path
                                      d="M23.6804 6.07409L18.2259 0.619583C17.7999 0.193537 17.1092 0.193537 16.6831 0.619583C16.2571 1.04563 16.2571 1.73628 16.6831 2.16233L20.2754 5.75464H1.09096C0.488472 5.75464 3.51626e-05 6.24307 3.51626e-05 6.84556C3.51626e-05 7.44804 0.488472 7.93648 1.09096 7.93648H20.2754L16.6832 11.5287C16.2571 11.9548 16.2571 12.6455 16.6832 13.0715C16.8961 13.2845 17.1753 13.391 17.4545 13.391C17.7337 13.391 18.0129 13.2845 18.2258 13.0714L23.6804 7.61688C24.1064 7.19084 24.1064 6.50013 23.6804 6.07409Z"
                                      fill="#ffa85d"
                                    />
                                    <path
                                      d="M22.9091 16.6637H3.72462L7.31683 13.0714C7.74288 12.6453 7.74288 11.9547 7.31683 11.5286C6.89088 11.1026 6.20013 11.1026 5.77409 11.5286L0.319535 16.9831C-0.106512 17.4092 -0.106512 18.0998 0.319535 18.5259L5.77404 23.9804C5.98713 24.1935 6.26627 24.3 6.54546 24.3C6.82465 24.3 7.10388 24.1935 7.31679 23.9804C7.74283 23.5544 7.74283 22.8637 7.31679 22.4377L3.72457 18.8455H22.9091C23.5116 18.8455 24 18.357 24 17.7546C24 17.1521 23.5116 16.6637 22.9091 16.6637Z"
                                      fill="#ffa85d"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_518_2277">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="custom-sel-input-block">
                                <label className="h6 color-medium-gray">
                                  To
                                </label>
                                <AutoCompleteDropdown
                                  placeholder="To"
                                  value={arrivalTo}
                                  onChange={(inputValue) => {
                                    setArrivalTo(inputValue);
                                  }}
                                  showError={showError}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-xl-3 d-xl-flex align-items-center gap-16">
                              <div className="input-date-picker">
                                <label
                                  htmlFor="dep"
                                  className="h6 color-medium-gray"
                                >
                                  Departure
                                </label>
                                <DatePicker
                                  selected={depDate}
                                  onChange={(date) => setDepDate(date)}
                                  placeholderText="MM/DD/YYYY"
                                  dateFormat="dd-MMM-yyyy"
                                  className="sel-input date_from"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    cursor: "pointer",
                                  }}
                                  required
                                  minDate={new Date(today)}
                                  showMonthDropdown={false} // Disable month dropdown
                                  showYearDropdown={false} // Disable year dropdown
                                  maxDate={
                                    new Date(
                                      new Date().setFullYear(
                                        new Date().getFullYear() + 10
                                      )
                                    )
                                  } // Maximum date is 10 years from today
                                />
                              </div>
                              {flightType === "1" && (
                                <div className="input-date-picker">
                                  <label
                                    htmlFor="ret"
                                    className="h6 color-medium-gray"
                                  >
                                    Return Date
                                  </label>
                                  {/* <DatePicker selected={returnDate} onChange={(date) => setReturnDate(date)} /> */}
                                  <DatePicker
                                    selected={returnDate}
                                    onChange={(date) => setReturnDate(date)}
                                    placeholderText="MM/DD/YYYY"
                                    dateFormat="MM/dd/yyyy"
                                    className="sel-input date_from"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      cursor: "pointer",
                                    }}
                                    required
                                    minDate={
                                      depDate
                                        ? new Date(depDate.getTime() + 86400000)
                                        : new Date()
                                    }
                                    showMonthDropdown={false} // Disable month dropdown
                                    showYearDropdown={false} // Disable year dropdown
                                    maxDate={
                                      new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() + 10
                                        )
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-xl-3 d-xl-flex align-items-center gap-16">
                              <div className="custom-sel-input-block">
                                <div className="h6 color-medium-gray">
                                  Passengers No.
                                </div>
                                <div
                                  className="seat-booking color-black"
                                  onClick={togglePopup}
                                >
                                  <span className="total-passenger">
                                    {passengers.adult +
                                      passengers.child +
                                      passengers.infant}{" "}
                                    Passengers
                                  </span>
                                </div>

                                {showPopup && (
                                  <div
                                    ref={popupRef}
                                    className="passenger-area pessenger-box bg-white light-shadow br-5 p-24"
                                    style={{ display: "block" }}
                                  >
                                    <h4 className="color-black mb-32">
                                      Passengers
                                    </h4>
                                    <div className="passenger-box mb-24">
                                      <div className="row">
                                        <div className="col-md-7 col-sm-6 col-6">
                                          <div className="content-box">
                                            <h5 className="lightest-black">
                                              Adult
                                            </h5>
                                            <p className="color-medium-gray light">
                                              {" "}
                                              Above 12 years.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-5 col-sm-6 col-6">
                                          <div className="quantity quantity-wrap">
                                            <button
                                              className="decrement"
                                              type="button"
                                              onClick={() =>
                                                handlePassengerChange(
                                                  "adult",
                                                  "decrement"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              className="number"
                                              type="text"
                                              value={passengers.adult}
                                              readOnly
                                              required
                                            />
                                            <button
                                              className="increment"
                                              type="button"
                                              onClick={() =>
                                                handlePassengerChange(
                                                  "adult",
                                                  "increment"
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="passenger-box mb-24">
                                      <div className="row">
                                        <div className="col-md-7 col-sm-6 col-6">
                                          <div className="content-box">
                                            <h5 className="lightest-black">
                                              Child
                                            </h5>
                                            <p className="color-medium-gray light">
                                              {" "}
                                              2-12 years on travel date.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-5 col-sm-6 col-6">
                                          <div className="quantity quantity-wrap">
                                            <button
                                              className="decrement"
                                              type="button"
                                              onClick={() =>
                                                handlePassengerChange(
                                                  "child",
                                                  "decrement"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              className="number"
                                              type="text"
                                              value={passengers.child}
                                              readOnly
                                            />
                                            <button
                                              className="increment"
                                              type="button"
                                              onClick={() =>
                                                handlePassengerChange(
                                                  "child",
                                                  "increment"
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="passenger-box mb-24">
                                      <div className="row">
                                        <div className="col-md-7 col-sm-6 col-6">
                                          <div className="content-box">
                                            <h5 className="lightest-black">
                                              Infant
                                            </h5>
                                            <p className="color-medium-gray light">
                                              {" "}
                                              Below 2 years.
                                            </p>
                                          </div>
                                        </div>
                                        <div className="col-md-5 col-sm-6 col-6">
                                          <div className="quantity quantity-wrap">
                                            <button
                                              className="decrement"
                                              type="button"
                                              onClick={() =>
                                                handlePassengerChange(
                                                  "infant",
                                                  "decrement"
                                                )
                                              }
                                            >
                                              -
                                            </button>
                                            <input
                                              className="number"
                                              type="text"
                                              value={passengers.infant}
                                              readOnly
                                            />
                                            <button
                                              className="increment"
                                              type="button"
                                              onClick={() =>
                                                handlePassengerChange(
                                                  "infant",
                                                  "increment"
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="color-medium-gray light mb-32">
                                      Please note: You can book for a maximum of
                                      nine passengers.
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div className="custom-sel-input-block">
                                <div className="h6 color-medium-gray">
                                  Class Type
                                </div>
                                <select
                                  className="sel-input"
                                  value={selectedOption}
                                  onChange={(e) =>
                                    handleOptionClick(e.target.value)
                                  }
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                >
                                  {options.map((option, index) => (
                                    <option key={index} value={index}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {multiCities.map((city, index) => (
                          <div
                            key={index}
                            className="row booking-info mb-16 city-row border-bottom pb-4 mb-2"
                          >
                            <div className="col-12 d-xl-flex align-items-center justify-content-between gap-16 d-lg-block p-0">
                              <div className="col-12 col-xl-5 d-xl-flex align-items-center gap-16">
                                <div className="custom-sel-input-block">
                                  <label className="h6 color-medium-gray">
                                    From
                                  </label>
                                  <AutoCompleteDropdown
                                    placeholder="From"
                                    readOnly
                                    value={
                                      index === 0
                                        ? arrivalTo
                                        : multiCities[index - 1]?.to || ""
                                    }
                                    showError={showError}
                                    disabled={true}
                                    onChange={() => {}} // No-op function to prevent errors
                                  />
                                </div>

                                <div className="arrows">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    className="mt-3"
                                  >
                                    <g clipPath="url(#clip0_518_2277)">
                                      <path
                                        d="M23.6804 6.07409L18.2259 0.619583C17.7999 0.193537 17.1092 0.193537 16.6831 0.619583C16.2571 1.04563 16.2571 1.73628 16.6831 2.16233L20.2754 5.75464H1.09096C0.488472 5.75464 3.51626e-05 6.24307 3.51626e-05 6.84556C3.51626e-05 7.44804 0.488472 7.93648 1.09096 7.93648H20.2754L16.6832 11.5287C16.2571 11.9548 16.2571 12.6455 16.6832 13.0715C16.8961 13.2845 17.1753 13.391 17.4545 13.391C17.7337 13.391 18.0129 13.2845 18.2258 13.0714L23.6804 7.61688C24.1064 7.19084 24.1064 6.50013 23.6804 6.07409Z"
                                        fill="#ffa85d"
                                      />
                                      <path
                                        d="M22.9091 16.6637H3.72462L7.31683 13.0714C7.74288 12.6453 7.74288 11.9547 7.31683 11.5286C6.89088 11.1026 6.20013 11.1026 5.77409 11.5286L0.319535 16.9831C-0.106512 17.4092 -0.106512 18.0998 0.319535 18.5259L5.77404 23.9804C5.98713 24.1935 6.26627 24.3 6.54546 24.3C6.82465 24.3 7.10388 24.1935 7.31679 23.9804C7.74283 23.5544 7.74283 22.8637 7.31679 22.4377L3.72457 18.8455H22.9091C23.5116 18.8455 24 18.357 24 17.7546C24 17.1521 23.5116 16.6637 22.9091 16.6637Z"
                                        fill="#ffa85d"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_518_2277">
                                        <rect
                                          width="24"
                                          height="24"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                                <div className="custom-sel-input-block">
                                  <label className="h6 color-medium-gray">
                                    To
                                  </label>
                                  <AutoCompleteDropdown
                                    placeholder="To"
                                    value={multiCities[index]?.to || ""}
                                    onChange={(newValue) =>
                                      handleCityChange(index, "to", newValue)
                                    }
                                    showError={showError}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-xl-3 d-xl-flex align-items-center gap-16">
                                <div className="input-date-picker">
                                  <label
                                    htmlFor="dep"
                                    className="h6 color-medium-gray"
                                  >
                                    Departure
                                  </label>
                                  <DatePicker
                                    selected={city.date} // Select next day
                                    onChange={(date) =>
                                      handleCityChange(index, "date", date)
                                    }
                                    placeholderText="MM/DD/YYYY"
                                    dateFormat="dd-MMM-yyyy"
                                    className="sel-input date_from"
                                    style={{
                                      width: "100%",
                                      padding: "10px",
                                      cursor: "pointer",
                                    }}
                                    required
                                    minDate={
                                      multiCities[index - 1]?.date || ""
                                        ? new Date(
                                            multiCities[
                                              index - 1
                                            ]?.date.getTime() + 86400000
                                          )
                                        : new Date()
                                    }
                                    // readOnly
                                    showMonthDropdown={false} // Disable month dropdown
                                    showYearDropdown={false} // Disable year dropdown
                                    maxDate={
                                      new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() + 10
                                        )
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-xl-3 d-xl-flex align-items-center gap-16 justify-content-end">
                                <button
                                  type="button"
                                  className="btn-delet mt-3"
                                  onClick={() => removeCity(index)}
                                >
                                  <MdOutlineDelete />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="cus-btn-outline"
                            onClick={addCity}
                          >
                            + Add City
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="row d-flex justify-content-end mt-4">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-9">
                        <div className="row align-items-center">
                          <div className="col-sm-6">
                            {/* <div className="booking-info promo-code mb-sm-0 mb-16">
                                                            <div className="custom-sel-input-block m-0">
                                                                <input
                                                                    type="text"
                                                                    className="sel-input p-0"
                                                                    id="promoCode"
                                                                    name="promoCode"
                                                                    value={bookingDetails.promoCode}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Enter Promo Code"
                                                                />
                                                            </div>
                                                        </div> */}
                          </div>
                          <div className="col-sm-6 d-flex justify-content-end">
                            {loading ? (
                              <Progress />
                            ) : (
                              <>
                                <button type="submit" className="cus-btn">
                                  {searchlabel}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_502_1331)">
                                      <path
                                        d="M31.6933 0.544584C30.6572 -0.491824 27.1402 1.34503 26.1041 2.38143L21.9545 6.53127H3.07887C2.63024 6.53127 2.24462 6.85011 2.16055 7.29104C2.07669 7.73189 2.31798 8.16995 2.73524 8.3348L15.2174 13.2677L7.5633 20.9216H0.323909C0.168651 20.9221 0.0346723 21.0323 0.00576263 21.1852C-0.023357 21.3385 0.060152 21.4901 0.20498 21.5471L6.29687 23.9548C6.33201 24.1078 6.38108 24.2574 6.44394 24.4038L6.17745 24.6709C5.79778 25.0503 5.79778 25.6651 6.17745 26.045C6.55664 26.4247 7.17263 26.4247 7.55182 26.045L7.81194 25.785C7.95935 25.8501 8.11132 25.9014 8.26623 25.9382L10.6144 31.9561C10.6709 32.1013 10.8229 32.1851 10.976 32.1568C11.0419 32.145 11.1002 32.1123 11.1451 32.0673C11.2044 32.0087 11.2399 31.9274 11.2399 31.8382V24.7512L19.0155 16.976L23.9019 29.4993C24.0654 29.9177 24.5037 30.1608 24.9452 30.0781C25.136 30.0421 25.3038 29.9498 25.4333 29.8212C25.6038 29.6499 25.7071 29.4151 25.7077 29.1591V10.284L29.8573 6.13423C30.893 5.09789 32.7293 1.58085 31.6933 0.544584Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_502_1391">
                                        <rect
                                          width="32"
                                          height="32"
                                          fill="black"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flight-listing-page mb-60">
        <div className="container">
          <div className="row">
            {tempflightData && tempflightData.length > 0 ? (
              selectedlistbooking.length !== 0 ? (
                <FlightBooking
                  data={selectedlistbooking}
                  traveltype={traveltype}
                  tripinfo={tripinfodetails}
                  onUpdate={(updatedItem) => {
                    Setselectedlistbooking([]);
                    Setselectedlist([]);
                  }}
                  airlines={airlines}
                />
              ) : (
                <>
                  {flightsdata && flightsdata.length === 0 ? (
                    <div className="col-xl-8 col-lg-8">
                      {" "}
                      <Container
                        maxWidth={false}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "50px 20px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src={NoFlightsSVG}
                          alt="No Flights"
                          style={{
                            maxWidth: "300px",
                            borderRadius: "8px",
                            marginBottom: "20px",
                          }}
                        />
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: "bold",
                            color: "#343a40",
                            fontFamily: "Roboto, sans-serif",
                            marginBottom: "10px",
                          }}
                        >
                          Oops! No Flights Available ✈️
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{
                            fontSize: "18px",
                            color: "#6c757d",
                            marginBottom: "30px",
                          }}
                        >
                          It looks like there are no flights available at the
                          moment. Please check again later.
                        </Typography>
                      </Container>
                    </div>
                  ) : (
                    <div className="col-xl-8 col-lg-8">
                      <div className="first-tab">
                        <div className="tab-container">
                          {tripinfodetails.map((item, index) => (
                            <div
                              key={index}
                              className={`tab ${
                                currenttab === index ? "active" : ""
                              }`}
                              onClick={() => openTab(index)}
                            >
                              {item.Origin} - {item.Destination} <br />{" "}
                              {formatDate(item.TravelDate)}
                            </div>
                          ))}
                        </div>
                      </div>

                      <FlightList
                        flights={flightsdata}
                        skey={searchkey}
                        flightsPerPage={5}
                        adult={passengers}
                        selectedTab={currenttab}
                        onUpdate={(updatedItem) => {
                          setflightitem(updatedItem);
                        }}
                        onUpdatenew={(updatedItem) => {
                          setselectedlistin(updatedItem);
                        }}
                        selectedlist={selectedlist}
                        airlinelist={airlines}
                      />
                    </div>
                  )}
                  <div className="col-xl-4 col-lg-4 mb-xl-0 mb-32">
                    <div className="sidebar pb-2 bg-white br-10 light-shadow mb-4">
                      <div className="sidebar-title d-flex justify-content-between align-items-center">
                        <h4 className="lightest-black">Filter Search</h4>
                        <p className="cler-cls" onClick={handleClear}>
                          Clear
                        </p>
                      </div>
                      <div className="filter-block plr-24 box-3">
                        <div
                          className="title mb-16 mt-16 d-flex justify-content-between align-items-center"
                          onClick={() =>
                            setAirlinesExpanded(!isAirlinesExpanded)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <h4 className="color-black fw-500">Airlines</h4>
                          <i
                            className={`fal fa-chevron-${
                              isAirlinesExpanded ? "up" : "down"
                            } color-primary`}
                          ></i>
                        </div>
                        {isAirlinesExpanded && (
                          <div className="content-block">
                            {Object.entries(airlineCounts).map(
                              ([airlineName, count], index) => (
                                <div className="custom-control" key={index}>
                                  <div className="d-flex justify-content-between align-items-center mb-24">
                                    <div className="radio-button">
                                      <input
                                        type="radio"
                                        name="airline"
                                        className="custom-control-input"
                                        onChange={(e) =>
                                          handleFilter(e, "airline")
                                        }
                                        value={airlineName}
                                        id={airlineName
                                          .replace(/\s/g, "")
                                          .toLowerCase()}
                                        checked={
                                          selectedAirline === airlineName
                                        }
                                      />
                                      <label
                                        className="custom-control-label lightest-black"
                                        htmlFor={airlineName
                                          .replace(/\s/g, "")
                                          .toLowerCase()}
                                      >
                                        {airlineName}
                                      </label>
                                    </div>
                                    <h5 className="light-black">({count})</h5>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                        <hr className="bg-sec-gray mb-16" />
                      </div>
                      <div className="filter-block plr-24 box-3">
                        <div
                          className="title mb-16 mt-16 d-flex justify-content-between align-items-center"
                          onClick={() =>
                            setRefundableExpanded(!isRefundableExpanded)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <h4 className="color-black fw-500">Fare Type</h4>
                          <i
                            className={`fal fa-chevron-${
                              isRefundableExpanded ? "up" : "down"
                            } color-primary`}
                          ></i>
                        </div>
                        {isRefundableExpanded && (
                          <div className="content-block">
                            <div className="custom-control" key="Refundable">
                              <div className="d-flex justify-content-between align-items-center mb-24">
                                <div className="radio-button">
                                  <input
                                    type="radio"
                                    name="refund"
                                    className="custom-control-input"
                                    onChange={(e) => handleFilter(e, "refund")}
                                    value="yes"
                                    checked={selectedRefundable === "yes"}
                                    id="refund"
                                  />
                                  <label
                                    className="custom-control-label lightest-black"
                                    htmlFor="refund" // Updated to match the input ID
                                  >
                                    Refundable
                                  </label>
                                </div>
                                <h5 className="light-black">
                                  {
                                    tempflightData.filter((flight) =>
                                      flight.Fares.some(
                                        (fare) => fare.Refundable
                                      )
                                    ).length
                                  }
                                  {/* ({Refundablecount}) */}
                                </h5>
                              </div>
                            </div>
                            <div className="custom-control" key="NONRefundable">
                              <div className="d-flex justify-content-between align-items-center mb-24">
                                <div className="radio-button">
                                  <input
                                    type="radio"
                                    name="refund"
                                    className="custom-control-input"
                                    onChange={(e) => handleFilter(e, "refund")} // Fixed the handler
                                    checked={selectedRefundable === "no"}
                                    value="no"
                                    id="nonrefund"
                                  />
                                  <label
                                    className="custom-control-label lightest-black"
                                    htmlFor="nonrefund" // Updated to match the input ID
                                  >
                                    NON Refundable
                                  </label>
                                </div>
                                <h5 className="light-black">
                                  {
                                    tempflightData.filter((flight) =>
                                      flight.Fares.some(
                                        (fare) => fare.Refundable === false
                                      )
                                    ).length
                                  }
                                  {/* ({Refundablecount}) */}
                                </h5>
                              </div>
                            </div>
                          </div>
                        )}
                        <hr className="bg-sec-gray mb-16" />
                      </div>

                      <div className="filter-block plr-24 box-3">
                        <div
                          className="title mb-16 mt-16 d-flex justify-content-between align-items-center"
                          onClick={() => setStopExpanded(!isStopExpanded)}
                          style={{ cursor: "pointer" }}
                        >
                          <h4 className="color-black fw-500">Stop</h4>
                          <i
                            className={`fal fa-chevron-${
                              isStopExpanded ? "up" : "down"
                            } color-primary`}
                          ></i>
                        </div>
                        {isStopExpanded && (
                          <div className="content-block">
                            <div className="custom-control" key="Refundable">
                              <div className="d-flex justify-content-between align-items-center mb-24">
                                <div className="radio-button">
                                  <input
                                    type="radio"
                                    name="stop"
                                    className="custom-control-input"
                                    onChange={(e) => handleFilter(e, "stop")}
                                    value="nonstop"
                                    checked={selectedstop === "nonstop"}
                                    id="stop"
                                  />
                                  <label
                                    className="custom-control-label lightest-black"
                                    htmlFor="stop" // Updated to match the input ID
                                  >
                                    Non Stop
                                  </label>
                                </div>
                                <h5 className="light-black">
                                  {
                                    tempflightData.filter(
                                      (flight) => flight.Segments.length === 1
                                    ).length
                                  }
                                  {/* ({Refundablecount}) */}
                                </h5>
                              </div>
                            </div>
                            <div className="custom-control" key="STOP">
                              <div className="d-flex justify-content-between align-items-center mb-24">
                                <div className="radio-button">
                                  <input
                                    type="radio"
                                    name="stop"
                                    className="custom-control-input"
                                    onChange={(e) => handleFilter(e, "stop")} // Fixed the handler
                                    checked={selectedstop === "1stop"}
                                    value="1stop"
                                    id="1stop"
                                  />
                                  <label
                                    className="custom-control-label lightest-black"
                                    htmlFor="1stop" // Updated to match the input ID
                                  >
                                    1 Stop
                                  </label>
                                </div>
                                <h5 className="light-black">
                                  {
                                    tempflightData.filter(
                                      (flight) => flight.Segments.length === 2
                                    ).length
                                  }
                                  {/* ({Refundablecount}) */}
                                </h5>
                              </div>
                            </div>

                            <div className="custom-control" key="2STOP">
                              <div className="d-flex justify-content-between align-items-center mb-24">
                                <div className="radio-button">
                                  <input
                                    type="radio"
                                    name="stop"
                                    className="custom-control-input"
                                    onChange={(e) => handleFilter(e, "stop")} // Fixed the handler
                                    checked={selectedstop === "2stop"}
                                    value="2stop"
                                    id="2stop"
                                  />
                                  <label
                                    className="custom-control-label lightest-black"
                                    htmlFor="2stop" // Updated to match the input ID
                                  >
                                    2+ Stop
                                  </label>
                                </div>
                                <h5 className="light-black">
                                  {
                                    tempflightData.filter(
                                      (flight) => flight.Segments.length > 2
                                    ).length
                                  }
                                  {/* ({Refundablecount}) */}
                                </h5>
                              </div>
                            </div>
                          </div>
                        )}{" "}
                        <hr className="bg-sec-gray mb-16" />
                      </div>
                    </div>

                    {selectedlist.length > 0 && (
                      <>
                        <div className="flight-booking flisting-detls">
                          <div className="flight-booking-detail light-shadow mb-32">
                            <div className="flight-title ">
                              <h4 className="color-black">
                                Your Selected Booking Details
                              </h4>
                            </div>
                            {selectedlistloading ? (
                              <></>
                            ) : (
                              selectedlist.map((Data, index) => (
                                <div className="box bg-white p-24 border-top">
                                  <div className="row bookingdetails_aireline">
                                    <div className="airline-name-outside">
                                      <div className="airline-name">
                                        <Airlogo
                                          airCode={Data.flight.Airline_Code}
                                          type={0}
                                          airlinelist={airlines}
                                        />
                                        <div>
                                          <div className="d-flex gap-2">
                                            <h5 className="lightest-black mb-8">
                                              {
                                                Data.flight?.Segments.at(0)
                                                  .Airline_Name
                                              }
                                            </h5>
                                            <h6 className="dark-gray">
                                              {Data.flight.Flight_Numbers}
                                            </h6>
                                          </div>
                                          <div className="d-flex justify-content-between">
                                            <p className="mb-0 text-muted">
                                              {
                                                Data.item.FareDetails[0]
                                                  .FareClasses[0].Class_Desc
                                              }{" "}
                                              (
                                              {
                                                Data.item.FareDetails[0]
                                                  .FareClasses[0].Class_Code
                                              }
                                              ):
                                            </p>
                                            {
                                              Data.item.FareDetails[0]
                                                .FareClasses[0].FareBasis
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="fair-ruls">
                                      <p
                                        onClick={() =>
                                          openModal(
                                            Data.item.Fare_Id,
                                            Data.flight.Flight_Key,
                                            Data.Search_Key
                                          )
                                        }
                                      >
                                        {fareloding ? (
                                          <Progress />
                                        ) : (
                                          "Show Rules"
                                        )}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="fare-class mb-2">
                                    <div className="ps-2">
                                      {/* {Details.item.FareDetails[0].FareClasses.map((fareClass, idx) => ( */}

                                      {/* ))} */}
                                    </div>
                                  </div>
                                  {/* <div className="flight-detail mb-32">
                                    <div className="flight-departure">
                                      <h5 className="dark-gray text-end">
                                        {Data.flight.Segments[0].Origin}
                                      </h5>
                                    </div>
                                    <div className="d-inline-flex align-items-center gap-8">
                                      <span>From</span>
                                      <div className="from-to text-center">
                                        <h5 className="dark-gray">
                                          {Data.flight.Segments[0].Duration}
                                        </h5>
                                        <img
                                          className="route-plan"
                                          src={route_plane}
                                          alt="Route Plan"
                                        />
                                        <h6 className="color-black">
                                          {Data.flight.Segments[0].Stop_Over}
                                        </h6>
                                      </div>
                                      <span>To</span>
                                    </div>
                                    <div className="flight-departure">
                                      <h5 className="dark-gray">
                                        {Data.flight.Segments[0].Destination}
                                      </h5>
                                    </div>
                                  </div>

                                  
                                  <div className="d-flex justify-content-around mb-16">
                                    <div className="flight-departure">
                                      <h6 className="dark-gray">Departure</h6>
                                      <h5 className="color-black">
                                        {formatDatetime(Data.flight.Segments[0]
                                          .Departure_DateTime)

                                        }
                                      </h5>
                                    </div>
                                    <div className="vr-line"></div>
                                    <div className="flight-departure">
                                      <h6 className="dark-gray">Arrival</h6>
                                      <h5 className="color-black">
                                        {
                                          formatDatetime(Data.flight.Segments[0]
                                            .Arrival_DateTime)
                                        }
                                      </h5>
                                    </div>
                                  </div> */}

                                  {Data.flight.Segments.map(
                                    (segment, index) => (
                                      <div className="flight-detail mt-4 mb-20">
                                        <div className="flight-departure">
                                          <h5 className="color-black">
                                            {formatDatetime(
                                              segment.Departure_DateTime
                                            )}
                                          </h5>
                                          <h5 className="dark-gray text-end">
                                            {segment.Origin_City}
                                          </h5>
                                        </div>
                                        <div className="d-inline-flex align-items-center gap-8">
                                          <span>To</span>
                                          <div className="from-to text-center">
                                            <h5 className="dark-gray">
                                              {segment.Duration}
                                            </h5>
                                            <img
                                              className="f_icon_list"
                                              src={route_plane}
                                              alt="route-plane"
                                            />
                                            <h6 className="color-black">
                                              {segment.Stop_Over} Stop
                                            </h6>
                                          </div>
                                          <span>From</span>
                                        </div>
                                        <div className="flight-departure">
                                          <h5 className="color-black">
                                            {formatDatetime(
                                              segment.Arrival_DateTime
                                            )}
                                          </h5>
                                          <h5 className="dark-gray">
                                            {segment.Destination_City}
                                          </h5>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              ))
                            )}

                            <div className="d-flex align-items-center justify-content-between mb-16 mt-4 mx-3 mb-2">
                              <h5 className="color-black fw-bold fs-5">
                                ₹
                                {selectedlist.reduce((acc, data) => {
                                  return (
                                    acc +
                                    (Number(
                                      data.item.FareDetails.find(
                                        (fare) => fare.PAX_Type === 0
                                      )?.Total_Amount || 0
                                    ) *
                                      Number(passengers.adult || 0) +
                                      // Number(Details.charges) +
                                      Number(
                                        data.item.FareDetails.find(
                                          (fare) => fare.PAX_Type === 1
                                        )?.Total_Amount || 0
                                      ) *
                                        Number(passengers.child || 0) +
                                      Number(
                                        data.item.FareDetails.find(
                                          (fare) => fare.PAX_Type === 2
                                        )?.Total_Amount || 0
                                      ) *
                                        Number(passengers.infant || 0) +
                                      Number(agencycharge))
                                  );
                                }, 0)}
                                {/* {selectedlist.reduce((acc, data) => {
                                  return (
                                    acc +
                                    data.item.FareDetails.reduce(
                                      (fareAcc, fare) => {
                                        return (
                                          fareAcc + Number(fare.Total_Amount)
                                        );
                                      },
                                      0
                                    )
                                  );
                                }, 0)} */}
                              </h5>

                              <button
                                onClick={() => {
                                  if (
                                    Array.isArray(tripinfodetails) &&
                                    Array.isArray(selectedlist)
                                  ) {
                                    if (
                                      tripinfodetails.length ===
                                      selectedlist.length
                                    ) {
                                      Setselectedlistbooking(selectedlist);
                                    } else {
                                      alert("Please select required fair");
                                    }
                                  } else {
                                    alert(
                                      "tripinfodetails or selectedlist is not an array."
                                    );
                                  }
                                }}
                                className="cus-btn btn-sec"
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <Modal
                      show={modalIsOpen}
                      onHide={closeModal}
                      size="xl"
                      backdrop="static"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Fare Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div
                          dangerouslySetInnerHTML={{ __html: fareDetails }}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-secondary"
                          onClick={closeModal}
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )
            ) : (
              <Container
                maxWidth={false}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "50px 20px",
                  textAlign: "center",
                }}
              >
                <img
                  src={NoFlightsSVG}
                  alt="No Flights"
                  style={{
                    maxWidth: "300px",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                />
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#343a40",
                    fontFamily: "Roboto, sans-serif",
                    marginBottom: "10px",
                  }}
                >
                  Oops! No Flights Available ✈️
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{
                    fontSize: "18px",
                    color: "#6c757d",
                    marginBottom: "30px",
                  }}
                >
                  It looks like there are no flights available at the moment.
                  Please check again later.
                </Typography>
              </Container>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Flight_listing;
