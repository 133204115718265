import logo from "../assets/images/logo.png"

export async function apiurl(callback) {

    let setting = null;
    let settingFromSession = sessionStorage.getItem('settting');
    if (settingFromSession && settingFromSession != null) {
        setting = JSON.parse(settingFromSession);
    }

    callback(setting.razorpay_prod_on === 1 ? setting.razorpay_prod_key : setting.razorpay_key)
}

export async function razarpaypayment(orderId, amount, description, RefNo, callback) {
    let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    let userData = null;
    let userDataFromSession = sessionStorage.getItem('userData');
    if (userDataFromSession && userDataFromSession != null) {
        userData = JSON.parse(userDataFromSession);
    }
    let setting = null;

    let settingFromSession = sessionStorage.getItem('settting');
    if (settingFromSession && settingFromSession != null) {
        setting = JSON.parse(settingFromSession);
    }

    let options = {
        key: setting.razorpay_prod_on === 1 ? setting.razorpay_prod_key : setting.razorpay_key,
        amount: parseInt(amount * 100),
        currency: userData.currency_code || "INR",
        name: "Vivan Travels",
        description: description,
        image: logo,
        handler: (response) => callback(response, amount, RefNo),
        prefill: {
            name: "Vivan Travels",
            email: "test@gmail.com",
            contact: userData.mobile_no,
        },
        notes: {
            address: "India",
        },
        theme: {
            color: "#158993",
        },
    };

    let paymentObject = new window.Razorpay(options);
    paymentObject.open();
};

let loadScript = (src) => {
    return new Promise((resolve) => {
        let script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};
