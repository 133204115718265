import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import ProfileSidebarWidget from '../../profile-sidebar';
import { ToastContainer, toast } from "react-toastify";
import MenuIcons from '../../menu-icons';
import '../../../flight-listing/booking-area-listing.css';
import './visa-status.css';
import { post } from "../../../../API/apiHelper";
import { applied_visa_list, IMAGE_BASE_URL } from "../../../../API/endpoints";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const VisaStatus = () => {

  const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('all');
    const [isLoading, setIsLoading] = useState(false);
    const [visaData, setDetails] = useState([]);
    const [userData, setUserData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);



    useEffect(() => {
        const userDataFromSession = sessionStorage.getItem('userData');
        if (userDataFromSession) {
            const userData = JSON.parse(userDataFromSession);
            setUserData(userData.model);
        }else{
            navigate("/login");
        }
    }, []);

    const handleTabClick = (tab) => setActiveTab(tab);

    const fetchDetails = async () => {
        setIsLoading(true);
        try {
            const response = await post(applied_visa_list, { id: userData.id, page: '1', limit: '10' }, true);
            if (response.ok) {
                const data = await response.json();
                setDetails(data.data);
            } else {
                console.error('Failed to fetch visa details');
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (userData) {
            fetchDetails();
        }
    }, [userData]);

    const filteredData = visaData && activeTab === 'all'
        ? visaData
        : visaData.filter(item => item.status.toLowerCase() === activeTab.toLowerCase());

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handlePageChangeVisa = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            fetchDetails(newPage);
        }
    };

    // Generate array for pages based on totalPages
    const pagesVisa = Array.from({ length: totalPages }, (_, index) => index + 1);


    return (
        <section className="pt-3 pb-5" style={{ minHeight: 'calc(100vh - 436px)' }}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="row">
                    <ProfileSidebarWidget />
                    <div className="col-xl-9 col-lg-8">
                        <MenuIcons />

                        <div className="vstack gap-4">
                            <div className="border bg-transparent card">
                                <div className="bg-transparent border-bottom card-header">
                                    <h3 className="card-header-title">Applied Visa History</h3>
                                </div>
                                <div className="p-0 card-body">
                                    <div className="p-2 p-sm-4 tab-content">
                                        <div role="tabpanel" className="fade tab-pane active show">
                                            <div className="border-0 mb-4 card">
                                                {filteredData.length > 0 ? (
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-striped table-hover m-0">
                                                            <thead className="thead-dark">
                                                                <tr>
                                                                    <th>Visa Status</th>
                                                                    <th>Name</th>
                                                                    <th>Submitted On</th>
                                                                    <th>Passport Number</th>
                                                                    <th>Pen Number</th>
                                                                    <th>Nationality</th>
                                                                    <th>Visa Type</th>
                                                                    <th>Status</th>
                                                                    <th>Remarks</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredData.map((visa) => (
                                                                    <tr key={visa.id}>
                                                                        <td>
                                                                            <span>Visa {visa.status.charAt(0).toUpperCase() + visa.status.slice(1)}</span>
                                                                        </td>
                                                                        <td>{visa.first_name+' '+visa.last_name}</td>
                                                                        <td>{visa.createdAt}</td>
                                                                        <td>{visa.passport_no}</td>
                                                                        <td>{visa.pen_card_no}</td>
                                                                        <td>{visa.nationality}</td>
                                                                        <td>{visa.visa_type}</td>
                                                                        <td>
                                                                            <span className={visa.status === "Reject" ? "text-danger" : ""}>
                                                                                {visa.status}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {(visa.remark)?(visa.remark):'N/A'}
                                                                            {/* {visa.status === "Reject" && <span className="text-danger">{visa.remark}</span>} */}
                                                                        </td>
                                                                        <td>
                                                                            <div className="action-buttons d-flex gap-2">
                                                                                {visa.created_file && (
                                                                                    <a
                                                                                        href={`${IMAGE_BASE_URL + visa.created_file}`}
                                                                                        className="btn btn-primary btn-sm action-button px-2 py-1"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        download="visa_file.pdf"
                                                                                    >
                                                                                        <i className="fa fa-download m-1"></i> {/* Font Awesome Icon */}
                                                                                        <span className="button-text">Download Visa</span>
                                                                                    </a>
                                                                                )}
                                                                                {visa.insurance_file && (
                                                                                    <a
                                                                                        href={`${IMAGE_BASE_URL + visa.insurance_file}`}
                                                                                        className="btn btn-primary btn-sm action-button px-2 py-1"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        download="insurance_file.pdf"
                                                                                    >
                                                                                        <i className="fa fa-download m-1"></i>
                                                                                        <span className="button-text">Download Insurance</span>
                                                                                    </a>
                                                                                )}

                                                                            </div>
                                                                            {/* <div className="action-buttons">
                                                                                {visa.created_file && (
                                                                                    <a
                                                                                        href={`${IMAGE_BASE_URL + visa.created_file}`}
                                                                                        className="btn btn-primary btn-sm mb-2"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        download="visa_file.pdf"
                                                                                    >
                                                                                        <i className="fa fa-download"></i> 
                                                                                        <span className="btn-text">Download Visa</span>
                                                                                    </a>
                                                                                )}
                                                                                {visa.insurance_file && (
                                                                                    <a
                                                                                        href={`${IMAGE_BASE_URL + visa.insurance_file}`}
                                                                                        className="btn btn-primary btn-sm"
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        download="insurance_file.pdf"
                                                                                    >
                                                                                        <i className="fa fa-download"></i>
                                                                                        <span className="btn-text">Download Insurance</span>
                                                                                    </a>
                                                                                )}
                                                                            </div> */}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div className="no-data-found mt-5">
                                                        <div className="text-center">
                                                            <div className="mt-4">
                                                                <svg xmlns="  " viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" width="50" height="50" className="text-danger">
                                                                    <path d="M12 2v20M2 12h20" />
                                                                </svg>
                                                                <h5 className="mt-4">No Data Available</h5>
                                                                <p>There are no records to display.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>


                                    {/* Pagination Controls */}
                                    <div className="table-responsive mt-4">
                                        <div className="px-4 d-flex justify-content-lg-end">
                                            <nav>
                                                <ul className="pagination">
                                                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                                        <button
                                                            className="page-link"
                                                            onClick={() => handlePageChangeVisa(currentPage - 1)}
                                                            disabled={currentPage === 1}>
                                                            ‹
                                                        </button>
                                                    </li>
                                                    {pagesVisa.map((page) => (
                                                        <li
                                                            key={page}
                                                            className={`page-item ${page === currentPage ? "active" : ""}`}
                                                            aria-current={page === currentPage ? "page" : null}>
                                                            {page === currentPage ? (
                                                                <span className="page-link">{page}</span>
                                                            ) : (
                                                                <button
                                                                    className="page-link"
                                                                    onClick={() => handlePageChangeVisa(page)}
                                                                >
                                                                    {page}
                                                                </button>
                                                            )}
                                                        </li>
                                                    ))}
                                                    <li
                                                        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                                        <button className="page-link" onClick={() => handlePageChangeVisa(currentPage + 1)}
                                                            disabled={currentPage === totalPages}>
                                                            ›
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    {/* End of Pagination */}

                                </div>
                            </div>
                        </div>





                        {/* {filteredData.length > 0 ? filteredData.map(visa => (
                            <div key={visa.id} className="visa-stats card my-4">
                                <div className="vs-name">
                                    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                                        <path d="M3.79297 13.9416C3.79297 12.0639 5.3152 10.5416 7.19297 10.5416H11.7263C13.6041 10.5416 15.1263 12.0639 15.1263 13.9416V13.9416C15.1263 15.1935 14.1115 16.2083 12.8596 16.2083H6.05963C4.80779 16.2083 3.79297 15.1935 3.79297 13.9416V13.9416Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path d="M12.293 4.87496C12.293 6.43977 11.0244 7.70829 9.45964 7.70829C7.89483 7.70829 6.6263 6.43977 6.6263 4.87496C6.6263 3.31015 7.89483 2.04163 9.45964 2.04163C11.0244 2.04163 12.293 3.31015 12.293 4.87496Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                    <span>Visa {visa.status.charAt(0).toUpperCase() + visa.status.slice(1)}</span>
                                </div>
                                <div className="card-body row">

                                    <div className="col-xl-4">
                                        <div className="first-ro">
                                            <div className="mb-3">
                                                <h5>{visa.name}</h5>
                                                <p>Submitted On : {visa.createdAt}</p>
                                                <p>Passport Number : {visa.passport_no}</p>
                                                <p>Pen Number : {visa.pen_card_no}</p>
                                            </div>
                                            <div className="mb-3">
                                                <h6>{visa.nationality}</h6>
                                                <p>Visa: {visa.visa_type}</p>
                                            </div>
                                            <div className="mb-0">
                                                <h6>Reference No:</h6>
                                                <p className="v-color">{visa.refrense_no}</p>
                                            </div>

                                            <div className="mb-0">
                                                <h6>Status:</h6>
                                                <p className="v-color">{visa.status}</p>
                                                {visa.status == "Reject" && (
                                                    <>
                                                        <p className="text-danger">{visa.remark}</p>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    {visa.created_file != null && (
                                        <a
                                            href={`${IMAGE_BASE_URL + visa.created_file}`}
                                            className="btn btn-primary "
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download="visa_file.pdf"  // Add a specific filename if possible
                                        >
                                            Download Visa
                                        </a>
                                    )
                                    }

                                    {visa.insurance_file != null && (
                                        <pre>  <a
                                            href={`${IMAGE_BASE_URL + visa.insurance_file}`}
                                            className="btn btn-primary"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download="insurance_file.pdf"  // Add a specific filename if possible
                                        >
                                            Download Insurance
                                        </a>
                                        </pre>
                                    )
                                    }
                                </div>




                            </div>
                        )) : (
                            <div className="no-data-found mt-5">
                                <div className="text-center">
                                    <div className="mt-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" width="50" height="50" className="text-danger">
                                            <path d="M12 2v20M2 12h20" />
                                        </svg>
                                        <h5 className="mt-4">No Data Available</h5>
                                        <p>There are no records to display.</p>
                                    </div>
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VisaStatus;
