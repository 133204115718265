import React from 'react';
import './whatsapp-support.css'

const WhatsappSupport = () => {
    return (
        <div className="whatsapp-support">
            <a href="https://wa.me/1234567890" className="whatsapp-link btn btn-success rounded-circle"
                target="_blank" rel="noopener noreferrer">
                <i className="whatsapp-icon fab fa-whatsapp fa-2x"></i>
            </a>
        </div>
    );
};

export default WhatsappSupport;
